import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import RegionPicker from "Containers/RegionPicker";

const Layout = styled.div`
  width: 100%;
`;

class RegionForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onChange, region, vendor } = this.props;

    return (
      <Layout>
        <RegionPicker onChange={onChange} region={region} vendor={vendor} />
      </Layout>
    );
  }
}

RegionForm.propTypes = {
  onChange: PropTypes.func,
  region: PropTypes.object,
  vendor: PropTypes.bool
};

export default RegionForm;
