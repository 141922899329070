import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router";

import PlatformWordmark from "Components/icons/PlatformWordmark";
import Cog from "Components/icons/Cog";
import Chart from "Components/icons/Chart";
import Code from "Components/icons/Code";
import Card from "Components/icons/Card";
import Account from "Components/icons/Account";
import Pin from "Components/icons/Pin";
import VendorIcon from "Icons/VendorIcon";

const Breadcrumb = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${props => props.theme.setupSidebar};
  color: #fff;
  padding: 0 24px 32px;
  box-sizing: border-box;
  width: 70px;
  font-size: 0;
  .logo {
    height: 32px;
    width: 122px;
    margin-top: 32px;
    margin-bottom: 60px;
    margin-left: 16px;
    svg {
      height: auto;
      width: 100%;
    }
  }
  .step {
    opacity: 0.5;
    padding: 0 24px;
    width: 236px;
    height: 40px;
    min-width: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    line-height: 18px;
    border-radius: 2px;
    margin-bottom: 16px;
  }
  a.step {
    height: 56px;
    margin-bottom: 8px;
    color: #fff;
  }
  a:hover {
    background: rgba(74, 73, 94, 0.5);
    cursor: pointer;
  }
  .active {
    opacity: 1;
    background: ${props => props.theme.setupSidebarActive};
    color: ${props => props.theme.setupSidebarActiveText};
    svg path {
      fill: ${props => props.theme.setupSidebarActiveText};
    }
  }
  .complete {
    opacity: 1;
  }
  @media (min-width: 600px) {
    font-size: 16px;
    width: 300px;
    min-width: 300px;
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  height: 24px;
  line-height: 24px;
  width: 24px;
  text-align: center;
  margin: 0 32px 0 0;
  overflow: hidden;
  svg {
    max-height: 100% !important;
    max-width: 100%;
  }
`;

const CompletedStepLabel = styled.div`
  color: #fff;
  opacity: 0.7;
  font-size: 10px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const CompletedStepValue = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 132px;
`;

class StepBreadCrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      steps,
      names,
      activeStep,
      totalSteps,
      goToStep,
      info,
      template,
      plan,
      region,
      account
    } = this.props;
    let VendorLogo;
    if (process.env.VENDOR_URL) {
      VendorLogo = require("Vendor_resources/images/logo-ui.svg");
    }

    return (
      <Breadcrumb className="breadcrumbs" role="complementary">
        {VendorLogo ? (
          <VendorIcon path={VendorLogo} size={30} />
        ) : (
          <PlatformWordmark alt="logo" color="#ffffff" size={122} />
        )}
        {steps.map(function(step, index) {
          if (step === "Building") {
            return;
          }
          const currentStep = index + 1;
          const isActive = currentStep === activeStep;
          let isComplete = false;
          if (step === "Create account" && account) {
            isComplete = true;
          }
          if (step === "Create project" && info) {
            isComplete = true;
          }
          if (step === "Set code base" && template) {
            isComplete = true;
          }
          if (step === "Plan size" && plan) {
            isComplete = true;
          }
          if (step === "Project region" && region) {
            isComplete = true;
          }

          return isComplete && activeStep < totalSteps ? (
            <Link
              key={index}
              id={`step-${step.replace(/\s+/g, "-").toLowerCase()}-complete`}
              className={`step step-${index + 1}${isActive ? " active" : ""}${
                isComplete ? " complete" : ""
              }`}
              onClick={e => {
                e.preventDefault();
                goToStep(names[index]);
              }}
              onKeyUp={e => e.keyCode === 13 && goToStep(names[index])}
              tabIndex="0"
            >
              {step === "Create account" && (
                <React.Fragment>
                  <IconWrapper>
                    <Account />
                  </IconWrapper>
                  <div>
                    <CompletedStepLabel>{names[index]}</CompletedStepLabel>
                    <CompletedStepValue
                      id={`step-${step
                        .replace(/\s+/g, "-")
                        .toLowerCase()}-value`}
                    >
                      {account}
                    </CompletedStepValue>
                  </div>
                </React.Fragment>
              )}
              {step === "Create project" && (
                <React.Fragment>
                  <IconWrapper>
                    <Cog />
                  </IconWrapper>
                  <div>
                    <CompletedStepLabel>Project</CompletedStepLabel>
                    <CompletedStepValue
                      id={`step-${step
                        .replace(/\s+/g, "-")
                        .toLowerCase()}-value`}
                    >
                      {info ? info.projectTitle : ""}
                    </CompletedStepValue>
                  </div>
                </React.Fragment>
              )}
              {step === "Set code base" && (
                <React.Fragment>
                  <IconWrapper>
                    <Code />
                  </IconWrapper>
                  <div>
                    <CompletedStepLabel>Code Base</CompletedStepLabel>
                    <CompletedStepValue
                      id={`step-${step
                        .replace(/\s+/g, "-")
                        .toLowerCase()}-value`}
                    >
                      {template && template.info.id === "platformsh/empty"
                        ? "Blank project"
                        : template.info.name}
                    </CompletedStepValue>
                  </div>
                </React.Fragment>
              )}
              {step === "Plan size" && (
                <React.Fragment>
                  <IconWrapper>
                    <Chart />
                  </IconWrapper>
                  <div>
                    <CompletedStepLabel>Plan size</CompletedStepLabel>
                    <CompletedStepValue
                      id={`step-${step
                        .replace(/\s+/g, "-")
                        .toLowerCase()}-value`}
                    >
                      {plan ? plan.title : ""}
                    </CompletedStepValue>
                  </div>
                </React.Fragment>
              )}
              {step === "Project region" && (
                <React.Fragment>
                  <IconWrapper>
                    <Pin />
                  </IconWrapper>
                  <div>
                    <CompletedStepLabel>Region</CompletedStepLabel>
                    <CompletedStepValue
                      id={`step-${step
                        .replace(/\s+/g, "-")
                        .toLowerCase()}-value`}
                    >
                      {region ? region.label : ""}
                    </CompletedStepValue>
                  </div>
                </React.Fragment>
              )}
            </Link>
          ) : (
            <div
              key={index}
              id={`step-${step.replace(/\s+/g, "-").toLowerCase()}`}
              className={`step step-${index + 1}${isActive ? " active" : ""}${
                isComplete ? " complete" : ""
              }`}
            >
              <IconWrapper>
                {step === "Create account" && <Account />}
                {step === "Create project" && <Cog />}
                {step === "Set code base" && <Code />}
                {step === "Plan size" && <Chart />}
                {step === "Project region" && <Pin />}
                {step === "Billing & Summary" && <Card />}
              </IconWrapper>
              <div>{step}</div>
            </div>
          );
        })}
      </Breadcrumb>
    );
  }
}

StepBreadCrumbs.propTypes = {
  steps: PropTypes.array,
  names: PropTypes.array,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  goToStep: PropTypes.func,
  info: PropTypes.object,
  plan: PropTypes.object,
  region: PropTypes.object,
  template: PropTypes.object,
  account: PropTypes.string
};

export default StepBreadCrumbs;
