import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { CardElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";
import { request } from "Libs/platform";
import config from "Constants/api_config";

import logger from "Libs/logger";

import Loading from "Components/Loading";
import Button from "Components/Button";
import Label from "Components/fields/Label";
import InputField from "Components/fields/InputField";
import RequiredTag from "Components/fields/RequiredTag";

const Layout = styled.div`
  .StripeElement {
    background: #f5f7fa;
    padding: 12px 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 32px;
  }
  .setting-line {
    background: #f5f7fa;
    border: 1px solid #f5f7fa;
    border-radius: 2px;
    min-height: 32px;
    line-height: 38px;
    height: 40px;
    font-size: 15px;
  }
  .card-number input {
    letter-spacing: 4px;
  }
`;

class CardSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creditcard: {},
      edit: props.edit,
      hideButtons: props.hideButtons
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.editLine = this.editLine.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });

    let creditcard;
    try {
      creditcard = await request(
        `${config.api_url}/platform/cardonfile`,
        "GET"
      );
      this.setState({
        isLoading: false,
        creditcard: creditcard.cardonfile,
        edit: false,
        hideButtons: false
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  editLine() {
    this.setState({
      edit: true,
      hideButtons: false
    });
  }

  cancel() {
    this.setState({
      edit: false
    });
  }

  async handleSubmit(ev) {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    if (this.state.edit) {
      this.setState({
        isLoading: true
      });
      try {
        const response = await this.props.stripe.createToken({
          type: "card",
          name: this.props.name
        });

        const cardTokenId = response.token.id;

        const creditcard = await request(
          `${config.api_url}/platform/cardonfile`,
          "POST",
          {
            token: cardTokenId
          }
        );

        this.setState({
          isLoading: false,
          creditcard,
          edit: false
        });

        this.props.onSuccess && this.props.onSuccess();
      } catch (err) {
        logger(err);
        this.setState({
          isLoading: false
        });
      }
    } else {
      this.props.onSuccess && this.props.onSuccess();
    }
  }

  render() {
    const { creditcard, isLoading = true, edit, hideButtons } = this.state;
    const { intl } = this.props;

    return (
      <Layout>
        <form aria-labelledby="edit-card" id="stripe-credit-card-form">
          {isLoading && <Loading />}
          {!edit && creditcard ? (
            <React.Fragment>
              <LiveMessage
                message="click update credit card to edit"
                aria-live="polite"
              />
              <InputField
                label="credit card number"
                className="card-number"
                isDisabled={true}
                value={creditcard.card_number
                  .replace(/X/g, "•")
                  .replace(/-/g, " ")}
              />
              <div>
                <Button onClick={this.editLine} type="button">
                  Update credit card
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <div className="new-card">
              <LiveMessage
                message="enter credit card information"
                aria-live="polite"
              />
              <Label>
                Credit card number <RequiredTag />
              </Label>
              <CardElement
                className="stripe-card"
                style={{
                  base: {
                    fontSize: "14px",
                    iconColor: "rgba(0,0,0,0.3)",
                    "::placeholder": {
                      fontSize: "14px",
                      color: "rgba(56, 72, 94, 0.5)"
                    }
                  }
                }}
                iconStyle="solid"
              />
              {!hideButtons && (
                <React.Fragment>
                  <Button
                    type="button"
                    aria-label={intl.formatMessage({ id: "save_changes" })}
                    id="stripe_cardsection_submit"
                    className="primary"
                    onClick={this.handleSubmit}
                  >
                    {intl.formatMessage({ id: "save_changes" })}
                  </Button>
                  {creditcard.card_number && (
                    <Button
                      type="button"
                      aria-label={intl.formatMessage({ id: "save_changes" })}
                      id="stripe_cardsection_cancel"
                      className="secondary"
                      onClick={this.cancel}
                    >
                      {"cancel"}
                    </Button>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </form>
      </Layout>
    );
  }
}

CardSection.propTypes = {
  name: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  intl: PropTypes.object,
  onSuccess: PropTypes.func
};

export default injectStripe(injectIntl(CardSection));
