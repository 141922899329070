import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import StepForm from "Components/setup/StepForm";
import StepHeader from "Components/setup/StepHeader";
import StepContent from "Components/setup/StepContent";
import InputField from "Components/fields/InputField";

class AccountStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "account"
    };

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goForward() {
    this.props.goForward(this.state);
  }

  goBack() {
    this.props.goBack(this.state);
  }

  render() {
    const { intl, activeStep, totalSteps, step, account } = this.props;

    return (
      <div>
        <StepHeader
          title="Account"
          totalStepNumber={totalSteps}
          currentStep={step}
        />
        <StepContent>
          <StepForm
            step={step}
            activeStep={activeStep}
            goForward={this.goForward}
            disableForward={account ? false : true}
            goBack={this.goBack}
            hideBackButton={true}
            totalStepNumber={totalSteps}
          >
            <InputField
              label={intl.formatMessage({ id: "user_name" })}
              value=""
            />
            <InputField
              label={intl.formatMessage({ id: "password" })}
              value=""
            />
          </StepForm>
        </StepContent>
      </div>
    );
  }
}

AccountStep.propTypes = {
  intl: PropTypes.object,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  account: PropTypes.string
};

export default injectIntl(AccountStep);
