import React from "react";
import PropTypes from "prop-types";

const CheckMark = ({ color = "#fff" }) => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.99976L5.24266 9.2424L13.0209 1.46422"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CheckMark.propTypes = {
  color: PropTypes.string
};

export default CheckMark;
