export const PLANS = [
  {
    value: 1,
    size: "development",
    label: "D",
    title: "Development",
    sku: "PLATFORM-ENVIRONMENT-DEVELOPMENT"
  },
  {
    value: 2,
    size: "standard",
    label: "S",
    title: "Standard",
    sku: "PLATFORM-ENVIRONMENT-STANDARD"
  },
  {
    value: 3,
    size: "medium",
    label: "M",
    title: "Medium",
    sku: "PLATFORM-ENVIRONMENT-MEDIUM"
  },
  {
    value: 4,
    size: "large",
    label: "L",
    title: "Large",
    sku: "PLATFORM-ENVIRONMENT-LARGE"
  },
  {
    value: 5,
    size: "xlarge",
    label: "XL",
    title: "Extra large",
    sku: "PLATFORM-ENVIRONMENT-XLARGE"
  },
  {
    value: 6,
    size: "2xlarge",
    label: "XXL",
    title: "Double extra large",
    sku: "PLATFORM-ENVIRONMENT-2X-LARGE"
  }
];

export const SUBSCRIPTION_DEFAULTS = {
  storage: 5120,
  environments: 3
};

export default {
  PLANS
};
