import React from "react";
import PropTypes from "prop-types";

const EnvironmentsIcon = () => (
  <svg
    width="43"
    height="40"
    viewBox="0 0 43 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.4415 28.0798V28.0287V23.6721C38.4415 23.5232 38.0576 23.3998 37.5918 23.3998H6.53135C6.06555 23.3998 5.68164 23.5232 5.68164 23.6721V27.8075"
      stroke="#5f5e70"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.2803 28.0798V14.0398"
      stroke="#5f5e70"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      opacity="0.3"
      d="M24.3992 12.48C27.8454 12.48 30.6391 9.68623 30.6391 6.23998C30.6391 2.79373 27.8454 0 24.3992 0C20.9529 0 18.1592 2.79373 18.1592 6.23998C18.1592 9.68623 20.9529 12.48 24.3992 12.48Z"
      fill="#5792FF"
    />
    <path
      d="M21.2781 14.0405C24.7243 14.0405 27.518 11.2468 27.518 7.80053C27.518 4.35428 24.7243 1.56055 21.2781 1.56055C17.8318 1.56055 15.0381 4.35428 15.0381 7.80053C15.0381 11.2468 17.8318 14.0405 21.2781 14.0405Z"
      stroke="#5f5e70"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M4.89999 39C7.05389 39 8.79998 37.254 8.79998 35.1001C8.79998 32.9462 7.05389 31.2001 4.89999 31.2001C2.74608 31.2001 1 32.9462 1 35.1001C1 37.254 2.74608 39 4.89999 39Z"
      fill="white"
      stroke="#C9D0E4"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M22.0592 39C24.2131 39 25.9592 37.254 25.9592 35.1001C25.9592 32.9462 24.2131 31.2001 22.0592 31.2001C19.9053 31.2001 18.1592 32.9462 18.1592 35.1001C18.1592 37.254 19.9053 39 22.0592 39Z"
      fill="white"
      stroke="#C9D0E4"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M37.6578 39C39.8117 39 41.5578 37.254 41.5578 35.1001C41.5578 32.9462 39.8117 31.2001 37.6578 31.2001C35.5039 31.2001 33.7578 32.9462 33.7578 35.1001C33.7578 37.254 35.5039 39 37.6578 39Z"
      fill="white"
      stroke="#C9D0E4"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

EnvironmentsIcon.propTypes = {
  color: PropTypes.string
};

export default EnvironmentsIcon;
