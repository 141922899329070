import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import countryList from "react-select-country-list";

import CardSection from "Components/CardSection";
import InputField from "Components/fields/InputField";
import CheckboxField from "Components/fields/CheckboxField";
import Description from "Components/fields/Description";
import Dropdown from "Components/Dropdown";
import Heading3 from "Components/styleguide/Heading3";
import Button from "Components/Button";
import client from "Libs/platform";
import { connect } from "react-redux";

const Layout = styled.div`
  padding: 0;
  .rccs {
    float: right;
  }
  button + button {
    margin-left: 10px;
  }
`;

const PaymentWrapper = styled.div`
  h3 {
    margin-top: 0;
    font-size: 15px;
    line-height: 33px;
  }
  form {
    width: 334px;
  }
  .full-width {
    margin-left: -126px;
    margin-right: -126px;
    margin-bottom: 24px;
    margin-top: 32px;
  }
`;

const AddressWrapper = styled.form`
  padding: 0 0 20px;
  .field {
    margin-bottom: 12px;
    width: 100%;
    &.administrative_area {
      width: 58%;
      display: inline-block;
    }
    &.postal_code {
      display: inline-block;
      width: calc(40% - 10px);
      float: right;
    }
    input {
      font-size: 14px;
      &::placeholder {
        color: #4a4a4a;
        opacity: 0.4;
      }
    }
    .Select-placeholder {
      font-size: 14px;
      color: #4a4a4a;
      opacity: 0.4;
    }
  }
  .inline-fields {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .dropdown {
    margin-bottom: 12px;
    .input-wrapper,
    .select-box {
      width: 100% !important;
    }
  }
`;

const CardWrapper = styled.div`
  padding: 0 0 32px;
  .checkbox-field {
    margin-bottom: 0;
    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .InputElement.is-empty {
    font-size: 14px;
    color: #4a4a4a;
    opacity: 0.4;
  }
`;

const Voucher = styled.div`
  color: #0e0d34;
  font-size: 32px;
  line-height: 33px;
  margin-bottom: 16px;
  font-weight: 700;
`;

const VoucherLabel = styled.label`
  font-size: 15px;
  font-weight: 600;
  line-height: 33px;
  color: #38485e;
  margin-bottom: 0;
`;

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      address: {
        name_line: props.name,
        thoroughfare: "",
        premise: "",
        locality: "",
        administrative_area: "",
        postal_code: "",
        country: ""
      },
      addressProxy: {
        administrative_area: null,
        country: null,
        dependent_locality: null,
        locality: null,
        name_line: null,
        postal_code: null,
        premise: null,
        sub_administrative_area: null,
        sub_premise: null,
        thoroughfare: null
      },
      expiry: "",
      cvc: "",
      focused: "",
      goingForward: false
    };

    this.onNumberChange = this.onNumberChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onExpiryChange = this.onExpiryChange.bind(this);
    this.onCvcChange = this.onCvcChange.bind(this);
    this.onThoroughfareChange = this.onThoroughfareChange.bind(this);
    this.onPremiseChange = this.onPremiseChange.bind(this);
    this.onLocalityChange = this.onLocalityChange.bind(this);
    this.onAdministrativeAreaChange = this.onAdministrativeAreaChange.bind(
      this
    );
    this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
    this.onConsentChange = this.onConsentChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.goForward = this.goForward.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  async componentDidMount() {
    const address = await client.getAddress(this.props.userId);
    this.setState(prevState => ({
      ...prevState,
      address: {
        name_line: address.name_line,
        thoroughfare: address.thoroughfare,
        premise: address.premise,
        locality: address.locality,
        administrative_area: address.administrative_area,
        postal_code: address.postal_code,
        country: address.country
      },
      addressProxy: address
    }));
  }

  onNumberChange(e) {
    const number = e.target.value;
    this.setState({
      number: number,
      focused: "number"
    });
  }
  onNameChange(e) {
    const name_line = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        name_line
      },
      focused: "name_line"
    }));
  }
  onExpiryChange(e) {
    const expiry = e.target.value;
    this.setState({
      expiry: expiry,
      focused: "expiry"
    });
  }
  onCvcChange(e) {
    const cvc = e.target.value;
    this.setState({
      cvc: cvc,
      focused: "cvc"
    });
  }

  onThoroughfareChange(e) {
    const thoroughfare = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        thoroughfare
      },
      focused: "thoroughfare"
    }));
  }

  onPremiseChange(e) {
    const premise = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        premise
      },
      focused: "premise"
    }));
  }

  onLocalityChange(e) {
    const locality = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        locality
      },
      focused: "locality"
    }));
  }

  onAdministrativeAreaChange(e) {
    const administrative_area = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        administrative_area
      },
      focused: "administrative_area"
    }));
  }

  onPostalCodeChange(e) {
    const postal_code = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        postal_code
      },
      focused: "postal_code"
    }));
  }

  onCountryChange({ value }) {
    const country = value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        country
      },
      focused: "country"
    }));
  }

  onConsentChange(event) {
    this.setState({
      consent: event.target.checked,
      consentError: undefined
    });
  }

  getNewAddressFields() {
    const { address, addressProxy } = this.state;
    let newFields = {};
    for (const p in address) {
      if (address[p] !== addressProxy[p]) {
        newFields[p] = address[p];
      }
    }
    return newFields;
  }

  goForward() {
    if (this.state.consent) {
      this.setState({ goingForward: true });
      const el = document.getElementById("stripe_cardsection_submit");
      if (el) return el.click();
      this.finalize();
    } else {
      this.setState({
        consentError: this.props.intl.formatMessage({ id: "check_consent" })
      });
    }
  }

  finalize() {
    const { addressProxy: address } = this.state;
    const { userId } = this.props;
    const newFields = this.getNewAddressFields();
    if (Object.keys(newFields).length > 0) {
      address.update(newFields, userId);
    }
    this.props.goForward();
  }

  onSuccess() {
    this.state.goingForward && this.finalize();
  }

  showPaymentInfo(showCardForm, estimate) {
    if (showCardForm && estimate && estimate.total) {
      if (estimate.total.amount === 0 && !estimate.credit) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      intl,
      goForwardText,
      goBack,
      goBackText,
      showCardForm,
      estimate
    } = this.props;
    const {
      address: {
        name_line,
        country,
        thoroughfare,
        premise,
        locality,
        administrative_area,
        postal_code
      }
    } = this.state;
    const defaultCountry = countryList()
      .getData()
      .find(i => i.value === country);
    return (
      <Layout>
        <PaymentWrapper>
          <AddressWrapper id="billing-address-form" className="address-form">
            <Heading3>
              {intl.formatMessage({ id: "billing_information" })}
            </Heading3>
            <InputField
              id="name_line"
              name="name_line"
              label={intl.formatMessage({ id: "full_name" })}
              placeholder={intl.formatMessage({ id: "full_name" })}
              className="name_line"
              value={name_line}
              onChange={this.onNameChange}
            />
            <InputField
              id="thoroughfare"
              name="thoroughfare"
              label={intl.formatMessage({ id: "address" })}
              placeholder={intl.formatMessage({ id: "address" })}
              className="address"
              value={thoroughfare}
              onChange={this.onThoroughfareChange}
              required={true}
            />
            <InputField
              id="premise"
              name="premise"
              label={intl.formatMessage({ id: "address2" })}
              placeholder={intl.formatMessage({ id: "address2" })}
              className="address"
              value={premise}
              onChange={this.onPremiseChange}
              required={false}
            />
            <Dropdown
              className="field-country"
              options={countryList().getData()}
              name="country"
              onChange={this.onCountryChange}
              searchable={false}
              value={defaultCountry}
              defaultValue={{ label: defaultCountry, value: defaultCountry }}
              clearable={false}
              label={intl.formatMessage({ id: "select_country" })}
              placeholder={intl.formatMessage({ id: "select_country" })}
              fieldType={true}
              required={true}
            />
            <InputField
              id="locality"
              name="locality"
              label={intl.formatMessage({ id: "city" })}
              placeholder={intl.formatMessage({ id: "city" })}
              className="locality"
              value={locality}
              onChange={this.onLocalityChange}
            />
            <div className="inline-fields">
              <InputField
                id="administrative_area"
                name="administrative_area"
                label={intl.formatMessage({
                  id: "state_province_region"
                })}
                placeholder={intl.formatMessage({
                  id: "state_province_region"
                })}
                className="administrative_area"
                value={administrative_area}
                onChange={this.onAdministrativeAreaChange}
              />
              <InputField
                id="postal_code"
                name="postal_code"
                label={intl.formatMessage({ id: "zip_postal_code" })}
                placeholder={intl.formatMessage({
                  id: "zip_postal_code"
                })}
                className="postal_code"
                value={postal_code}
                onChange={this.onPostalCodeChange}
              />
            </div>
          </AddressWrapper>

          {this.showPaymentInfo(showCardForm, estimate) && (
            <CardWrapper>
              <Heading3>
                {intl.formatMessage({ id: "payment_information" })}
              </Heading3>
              <Description color={showCardForm ? "#98a0ab" : "#000"}>
                {showCardForm
                  ? intl.formatMessage({
                      id: "payment_information_description"
                    })
                  : intl.formatMessage({ id: "payment_voucher_description" })}
              </Description>
              {showCardForm ? (
                <CardSection
                  edit={true}
                  hideButtons={false}
                  onSuccess={this.onSuccess}
                  name={name_line}
                />
              ) : (
                <React.Fragment>
                  <Voucher>
                    {estimate && estimate.credit && estimate.credit.formatted}
                  </Voucher>

                  <VoucherLabel>
                    {intl.formatMessage({ id: "voucher_credit" })}
                  </VoucherLabel>
                </React.Fragment>
              )}
              <hr className="full-width" />
            </CardWrapper>
          )}

          <CheckboxField
            onChange={this.onConsentChange}
            value={this.state.consent}
            error={this.state.consentError}
            label={intl.formatMessage({ id: "payment_consent" })}
            forId="consent"
          />
        </PaymentWrapper>
        <div className="action-buttons-wrapper">
          <div className="action-buttons">
            <Button
              id="create-project-back"
              type="button"
              className="secondary"
              onClick={goBack}
            >
              {goBackText}
            </Button>
            <Button
              id="create-project-submit"
              type="button"
              className="primary"
              onClick={this.goForward}
            >
              {goForwardText}
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}

PaymentForm.propTypes = {
  title: PropTypes.string,
  goBackText: PropTypes.string,
  goForwardText: PropTypes.string,
  errors: PropTypes.object,
  intl: PropTypes.object,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  name: PropTypes.string,
  userId: PropTypes.string,
  estimate: PropTypes.object,
  showCardForm: PropTypes.bool
};

const mapStateToProps = state => ({
  userId: state.app && state.app.getIn(["me", "id"])
});

export default connect(mapStateToProps)(injectIntl(PaymentForm));
