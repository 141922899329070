import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";

import CheckMark from "Icons/CheckMark";

const PlanName = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 8px;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const PlanSubTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const FeatureList = styled.ul`
  padding: 0;
  margin: 0 auto 30px;
  list-style: none;
  width: 400px;
  max-width: 100%;
`;

const FeatureListItem = styled.li`
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  min-height: 22px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    line-height: 16px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

class PlanInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl,
      plan,
      title,
      subtitles,
      theme = { buttonBg: "#4786FF" }
    } = this.props;
    return (
      <div className="plan-info">
        {title && (
          <PlanName data-sku={plan.sku}>
            {plan === "D"
              ? ""
              : intl.formatMessage({ id: "professional" }) + " "}
            {title}
          </PlanName>
        )}
        {plan === "D" && (
          <div>
            {subtitles && (
              <PlanSubTitle>
                {intl.formatMessage({ id: "development_description" })}
              </PlanSubTitle>
            )}
            <FeatureList>
              <FeatureListItem>
                <p>{intl.formatMessage({ id: "development_details" })}</p>
              </FeatureListItem>
            </FeatureList>
          </div>
        )}
        {(plan === "S" || plan === "standard") && (
          <div>
            {subtitles && (
              <PlanSubTitle>
                {intl.formatMessage({ id: "small_description" })}
              </PlanSubTitle>
            )}
            <FeatureList>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  0.8{" "}
                  {intl.formatMessage({
                    id: "gb_memory"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "low_med_traffic_websites"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <FormattedMessage id="handles_thousands" />
              </FeatureListItem>
            </FeatureList>
          </div>
        )}
        {(plan === "M" || plan === "medium") && (
          <div>
            {subtitles && (
              <PlanSubTitle>
                {intl.formatMessage({ id: "medium_description" })}
              </PlanSubTitle>
            )}
            <FeatureList>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  3{" "}
                  {intl.formatMessage({
                    id: "gb_memory"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "med_higher_traffic_websites"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "multi_app_multi_data_apps"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <FormattedMessage id="suitable_five_hund" />
              </FeatureListItem>
            </FeatureList>
          </div>
        )}
        {(plan === "L" || plan === "large") && (
          <div>
            {subtitles && (
              <PlanSubTitle>
                {intl.formatMessage({ id: "large_description" })}
              </PlanSubTitle>
            )}
            <FeatureList>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  6{" "}
                  {intl.formatMessage({
                    id: "gb_memory"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "higher_traffic_websites"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>{intl.formatMessage({ id: "multiple_backends" })}</span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "complex_micro_service_applications"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <FormattedMessage id="scales_mil" />
              </FeatureListItem>
            </FeatureList>
          </div>
        )}
        {(plan === "XL" || plan === "xlarge") && (
          <div>
            {subtitles && (
              <PlanSubTitle>
                {intl.formatMessage({ id: "xlarge_description" })}
              </PlanSubTitle>
            )}
            <FeatureList>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  12{" "}
                  {intl.formatMessage({
                    id: "gb_memory"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "higher_traffic_websites"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>{intl.formatMessage({ id: "multiple_backends" })}</span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "memory_intensive_apps"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "complex_micro_service_applications"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <FormattedMessage id="scales_few_mil" />
              </FeatureListItem>
            </FeatureList>
          </div>
        )}
        {(plan === "XXL" || plan === "2xlarge") && (
          <div>
            {subtitles && (
              <PlanSubTitle>
                {intl.formatMessage({ id: "xxlarge_description" })}
              </PlanSubTitle>
            )}
            <FeatureList>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  24{" "}
                  {intl.formatMessage({
                    id: "gb_memory"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "higher_traffic_websites"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>{intl.formatMessage({ id: "multiple_backends" })}</span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "memory_intensive_apps"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <span>
                  {intl.formatMessage({
                    id: "complex_micro_service_applications"
                  })}
                </span>
              </FeatureListItem>
              <FeatureListItem>
                <CheckMark color={theme.buttonBg} />{" "}
                <FormattedMessage id="scales_mils" />
              </FeatureListItem>
            </FeatureList>
          </div>
        )}
      </div>
    );
  }
}

PlanInfo.propTypes = {
  intl: PropTypes.object,
  plan: PropTypes.string,
  title: PropTypes.string,
  subtitles: PropTypes.bool,
  theme: PropTypes.object
};

export default injectIntl(PlanInfo);
