import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import SanitizedHTML from "react-sanitized-html";

import ModalWrapper from "Components/ModalWrapper";
import Heading3 from "Components/styleguide/Heading3";
import Heading4 from "Components/styleguide/Heading4";
import Button from "Components/Button";

const HeaderWrapper = styled.div`
  .base-icon {
    max-height: 40px;
    width: 40px;
    margin-bottom: 32px;
    padding-top: 16px;
    img {
      max-height: 100%;
    }
  }
  h3 {
    margin: 0 0 30px;
  }
`;

const TemplateInfo = styled.div`
  color: #4a495e;
  font-size: 15px;
  margin-bottom: 24px;
  h4 {
    margin: 0 0 8px;
  }
  &.description {
    color: #38485e;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 24px;
  }
  &.blank-description {
    margin-bottom: 48px;
  }
  &.settings {
    margin-bottom: 32px;
  }
`;

const ActionLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

class TemplateSelectModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      isOpen,
      closeModal,
      onSave,
      icon,
      title,
      description,
      template
    } = this.props;

    const empty =
      template && template.info && template.info.id === "platformsh/empty";
    const submitId =
      template &&
      template.info &&
      template.info.id &&
      "select-" + template.info.id.replace(/\//gm, "-");
    return (
      <ModalWrapper
        isOpen={isOpen}
        closeModal={closeModal}
        onRequestClose={closeModal}
        modalClass="modal-medium modal-add-template"
      >
        <HeaderWrapper>
          {empty ? (
            <Heading3>Create a blank project</Heading3>
          ) : (
            <React.Fragment>
              <div className="base-icon">
                <img alt={title} src={icon} />
              </div>
              <Heading3>{title}</Heading3>
            </React.Fragment>
          )}
        </HeaderWrapper>
        <TemplateInfo
          className={`description${empty ? " blank-description" : ""}`}
        >
          <SanitizedHTML
            className="description"
            allowedTags={["strong", "b", "br"]}
            html={description}
          />
        </TemplateInfo>
        {!empty &&
          template &&
          template.info &&
          template.info.notes &&
          template.info.notes.map(
            (note, i) =>
              (note.heading || note.content) && (
                <TemplateInfo key={i} className="apps-services">
                  {note.heading && <Heading4>{note.heading}</Heading4>}
                  {note.content && (
                    <SanitizedHTML
                      className="description"
                      allowedTags={["strong", "b", "br"]}
                      html={note.content}
                    />
                  )}
                </TemplateInfo>
              )
          )}
        <ActionLinks>
          <Button
            id={submitId}
            type="submit"
            aria-label="select template"
            onClick={onSave}
            className="primary"
          >
            {empty ? "Create blank project" : "Select template"}
          </Button>
        </ActionLinks>
      </ModalWrapper>
    );
  }
}

TemplateSelectModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onSave: PropTypes.func,
  template: PropTypes.object
};

export default injectIntl(TemplateSelectModal);
