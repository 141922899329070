import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const VendorLogo = styled.div`
  display: inline-block;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  img {
    width: 100%;
  }
`;

const VendorIcon = props => (
  <VendorLogo size={props.size}>
    <img src={props.path} alt="logo" />
  </VendorLogo>
);

VendorIcon.propTypes = {
  path: PropTypes.string,
  size: PropTypes.number
};

export default VendorIcon;
