import React from "react";
import PropTypes from "prop-types";

const StorageIcon = () => (
  <svg
    width="34"
    height="41"
    viewBox="0 0 34 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M32.9959 10H5.82645C5.3719 10 5 9.6281 5 9.17355V0.826446C5 0.371901 5.3719 0 5.82645 0H32.9959C33.4504 0 33.8223 0.371901 33.8223 0.826446V9.17355C33.8223 9.6281 33.4545 10 32.9959 10Z"
      fill="#5792FF"
    />
    <circle cx="8" cy="21" r="2" fill="#C9D0E4" />
    <circle cx="14" cy="21" r="2" fill="#C9D0E4" />
    <circle cx="8" cy="8" r="2" fill="#5f5e70" />
    <rect
      x="1"
      y="3"
      width="28px"
      height="10px"
      stroke="#5f5e70"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <rect
      x="1"
      y="16"
      width="28px"
      height="10px"
      stroke="#5f5e70"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <circle cx="8" cy="35" r="2" fill="#C9D0E4" />
    <circle cx="14" cy="35" r="2" fill="#C9D0E4" />
    <circle cx="20" cy="35" r="2" fill="#C9D0E4" />
    <rect
      x="1"
      y="30"
      width="28px"
      height="10px"
      stroke="#5f5e70"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

StorageIcon.propTypes = {
  color: PropTypes.string
};

export default StorageIcon;
