import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import Base from "Components/setup/Base";
import TemplateSelectModal from "./TemplateSelectModal";
import SearchInput from "Components/SearchInput";
import StepForm from "Components/setup/StepForm";
import Dropdown from "Components/Dropdown";

const Layout = styled.div`
  width: 100%;
  position: relative;
  .status-notice {
    display: inline-block;
    margin-left: 10px;
  }
  h4 {
    text-align: center;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .search-wrapper {
    width: 216px;
  }
  @media (min-width: 1010px) {
    flex-direction: row;
  }
`;

const ResultsWrapper = styled.div`
  clear: both;
  padding: 50px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media (min-width: 1010px) {
    flex-direction: row;
    align-items: stretch;
  }
  .stack {
    width: 275px;
    margin: 0 0 32px;
    clear: both;
    @media (min-width: 1009px) {
      margin-right: 32px;
    }
    @media (min-width: 1010px) and (max-width: 1252px) {
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    @media (min-width: 1253px) and (max-width: 1559px) {
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
    @media (min-width: 1560px) and (max-width: 1866px) {
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }
    @media (min-width: 1867px) {
      &:nth-child(5n + 5) {
        margin-right: 0;
      }
    }
  }
  .selected {
    position: fixed;
    z-index: 2;
    right: 15%;
    bottom: 10%;
    padding: 30px;
  }
  @media (min-width: 1250px) {
    justify-content: flex-start;
  }
`;

const SelectorWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 10px;
  z-index: 2;
  .input-wrapper {
    width: auto;
  }
  &.filter-language {
    .select-box {
      width: 156px;
    }
  }
`;

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.setLanguage = this.setLanguage.bind(this);
    this.setType = this.setType.bind(this);
    this.setBase = this.setBase.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.toggleType = this.toggleType.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.templateFilter = this.templateFilter.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.state = {
      language: "",
      type: "",
      base: "",
      baseTitle: "",
      baseDescription: "",
      languageOpen: false,
      typeOpen: false,
      modalOpen: false,
      modalIcon: "",
      search: "",
      template: {},
      modalTemplate: {}
    };
  }

  componentDidMount() {
    const template = this.props.template;
    if (template) {
      this.setState({
        template: template
      });
    }
  }
  setLanguage(value) {
    this.setState({
      language: value.value === "all" ? "" : value.value,
      languaOpen: false,
      typeOpen: false
    });
  }

  setType(value) {
    this.setState({
      type: value.value === "all" ? "" : value.value
    });
  }

  setBase(template, id, title, description, icon) {
    if (template) {
      this.props.onChange(template);
      this.setState({
        base: id,
        baseTitle: title,
        baseDescription: description,
        modalIcon: icon,
        template: template
      });
    } else {
      this.setState({
        base: "",
        baseTitle: "",
        baseDescription: "",
        modalIcon: "",
        template: {}
      });
    }
  }

  toggleLanguage() {
    this.setState({
      languageOpen: !this.state.languageOpen,
      typeOpen: false
    });
  }

  toggleType() {
    this.setState({
      languageOpen: false,
      typeOpen: !this.state.typeOpen
    });
  }

  openModal(template) {
    this.setState({
      modalOpen: true,
      modalTemplate: template
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
      modalTemplate: {}
    });
  }

  onSearchChange(event) {
    this.setState({
      search: event.target.value
    });
  }

  templateFilter(template) {
    const title = template.title;

    if (!title) {
      return true;
    }

    return title.toUpperCase().includes(this.state.search.toUpperCase());
  }

  render() {
    const {
      intl,
      templates,
      goForward,
      step,
      activeStep,
      totalSteps,
      goBack,
      template
    } = this.props;

    const languageList = [{ value: "all", label: "All languages" }];
    let typeList = [{ value: "all", label: "All types" }];
    if (templates) {
      templates.forEach(template => {
        if (template && template.info && template.info.tags) {
          template.info.tags.forEach((tag, i) => {
            if (
              i === 0 &&
              !languageList.find(listItem => listItem.label === tag)
            ) {
              languageList.push({ value: tag, label: tag });
            }
          });
          template.info.tags.forEach(tag => {
            if (
              !typeList.find(listItem => listItem.label === tag) &&
              !languageList.find(listItem => listItem.label === tag)
            ) {
              typeList.push({ value: tag, label: tag });
            }
          });
        }
      });
    }
    return (
      <Layout id="template-list" role="tabpanel" aria-labelledby="tab-template">
        <LiveMessage
          message={intl.formatMessage({ id: "tab_template" })}
          aria-live="polite"
        />
        {this.state.language && (
          <LiveMessage
            message={`filtering list by ${this.state.language}`}
            aria-live="polite"
          />
        )}
        {this.state.type && (
          <LiveMessage
            message={`filtering list by ${this.state.type}`}
            aria-live="polite"
          />
        )}
        {this.state.search && (
          <LiveMessage
            message={`filtering list by ${this.state.search}`}
            aria-live="polite"
          />
        )}
        <FiltersWrapper>
          <SearchInput
            id="template-search-input"
            placeholder={intl.formatMessage({ id: "search_templates" })}
            iconSize={18}
            onChange={this.onSearchChange}
            className="float"
          />
          <div>
            <SelectorWrapper className="filter-tags filter-language">
              <Dropdown
                options={languageList}
                name="select_language"
                onChange={this.setLanguage}
                searchable={false}
                defaultValue={{ value: "all", label: "All languages" }}
                clearable={false}
                className="outlined"
              />
            </SelectorWrapper>
            <SelectorWrapper className="filter-tags filter-type">
              <Dropdown
                options={typeList}
                name="select_type"
                onChange={this.setType}
                searchable={false}
                defaultValue={{ value: "all", label: "All types" }}
                clearable={false}
                className="outlined"
              />
            </SelectorWrapper>
          </div>
        </FiltersWrapper>
        <StepForm
          step={step}
          activeStep={activeStep}
          goForward={goForward}
          goBack={goBack}
          totalStepNumber={totalSteps}
          disableForward={template || this.state.base ? false : true}
        >
          <ResultsWrapper className="templates-wrapper">
            {templates &&
              templates.map((template, i) => {
                if (this.state.type && template.info && template.info.tags) {
                  if (template.info.tags.indexOf(this.state.type) === -1) {
                    return;
                  }
                }
                if (
                  this.state.language &&
                  template.info &&
                  template.info.tags
                ) {
                  if (template.info.tags.indexOf(this.state.language) === -1) {
                    return;
                  }
                }
                if (
                  this.state.search &&
                  template.info.name &&
                  !template.info.name
                    .toLowerCase()
                    .includes(this.state.search.toLowerCase())
                ) {
                  return;
                }
                return (
                  <Base
                    activeClass={
                      this.state.base
                        ? this.state.base
                        : this.state.template &&
                          this.state.template.info &&
                          this.state.template.info.id
                    }
                    template={template}
                    key={i}
                    onClick={() => {
                      if (
                        this.state.template &&
                        this.state.template.info &&
                        this.state.template.info.id === template.info.id
                      ) {
                        this.setBase();
                      } else {
                        this.setBase(
                          template,
                          template.info.id,
                          template.info.name,
                          template.info.description,
                          template.info.image
                        );
                      }
                    }}
                    openModal={() => {
                      this.openModal(template);
                    }}
                    modalOpen={this.state.modalOpen}
                  />
                );
              })}
          </ResultsWrapper>
        </StepForm>
        <TemplateSelectModal
          isOpen={this.state.modalOpen}
          title={
            this.state.modalTemplate.info && this.state.modalTemplate.info.name
          }
          description={
            this.state.modalTemplate.info &&
            this.state.modalTemplate.info.description
          }
          icon={
            this.state.modalTemplate.info && this.state.modalTemplate.info.image
          }
          onSave={() => {
            this.setBase(
              this.state.modalTemplate,
              this.state.modalTemplate.info.id,
              this.state.modalTemplate.info.name,
              this.state.modalTemplate.info.description,
              this.state.modalTemplate.info.image
            );
            this.closeModal();
          }}
          template={this.state.modalTemplate}
          closeModal={this.closeModal}
        />
      </Layout>
    );
  }
}

Templates.propTypes = {
  intl: PropTypes.object,
  templates: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
  activeStep: PropTypes.number,
  step: PropTypes.number,
  totalSteps: PropTypes.number,
  goBack: PropTypes.func,
  template: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default injectIntl(Templates);
