import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import StepForm from "Components/setup/StepForm";
import RegionForm from "Components/setup/steps/region/Form";
import StepHeader from "Components/setup/StepHeader";
import StepContent from "Components/setup/StepContent";

class RegionStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vendor: process.env.VENDOR_URL ? true : false
    };

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onRegionChange = this.onRegionChange.bind(this);
  }

  componentDidMount() {
    if (this.props.region) {
      this.setState({
        ...this.props.region
      });
    }
  }

  goForward() {
    this.props.goForward(this.state);
  }

  goBack() {
    this.props.goBack(this.state);
  }

  onRegionChange(region) {
    this.setState({
      region
    });
  }

  render() {
    const { intl, activeStep, totalSteps, step, region } = this.props;

    return (
      <div>
        <StepHeader
          title={intl.formatMessage({ id: "region" })}
          currentStep={activeStep}
          totalStepNumber={totalSteps}
        >
          Select your region, usually the region that is closest to the majority
          of your web traffic. This cannot be changed later.
        </StepHeader>
        <StepContent>
          <StepForm
            step={step}
            activeStep={activeStep}
            goForward={this.goForward}
            totalStepNumber={totalSteps}
            goBack={this.goBack}
            disableForward={region || this.state.region ? false : true}
          >
            <RegionForm
              onChange={this.onRegionChange}
              region={region}
              vendor={this.state.vendor}
            />
          </StepForm>
        </StepContent>
      </div>
    );
  }
}

RegionStep.propTypes = {
  initialization: PropTypes.object,
  intl: PropTypes.object,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  region: PropTypes.object
};

export default injectIntl(RegionStep);
