import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "Components/Button";

class StepForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextText: "Next"
    };

    this.parentTag = styled[props.parentTag || "form"]`
      width: 100%;
      .action-buttons-wrapper {
        position: fixed;
        bottom: 0;
        background: #fff;
        right: 0;
        width: calc(100% - 300px);
        border-top: 1px solid #c9d0e4;
        box-sizing: border-box;
        padding: 12px 32px;
        z-index: 2;
      }
      .action-buttons {
        display: flex;
        justify-content: flex-end;
        button + button {
          margin-left: 10px;
        }
      }
    `;
  }
  componentDidMount() {
    if (
      this.props.activeStep &&
      this.props.totalStepNumber &&
      this.props.activeStep === this.props.totalStepNumber - 2
    ) {
      this.setState({
        nextText: "Create Project"
      });
    }
  }
  render() {
    const {
      step,
      activeStep,
      goForward,
      goForwardText = this.state.nextText,
      disableForward = true,
      goBack,
      goBackText = "Back",
      hideButtons = false,
      hideContinueButton = false,
      hideBackButton = false
    } = this.props;

    if (step !== activeStep) {
      return false;
    }

    const ParentTag = this.parentTag;
    return (
      <div>
        <ParentTag id={`form-step-${step}`}>
          {this.props.children}
          {!hideButtons && (
            <div className="action-buttons-wrapper">
              <div className="action-buttons">
                {!hideBackButton && (
                  <Button
                    id={`form-step-${step}-back`}
                    className="secondary"
                    type="button"
                    onClick={goBack}
                    aria-label={goBackText}
                  >
                    {goBackText}
                  </Button>
                )}
                {!hideContinueButton && (
                  <Button
                    id={`form-step-${step}-submit`}
                    type="submit"
                    className="primary"
                    onClick={goForward}
                    aria-label={goForwardText}
                    disabled={disableForward}
                  >
                    {goForwardText}
                  </Button>
                )}
              </div>
            </div>
          )}
        </ParentTag>
      </div>
    );
  }
}

StepForm.propTypes = {
  step: PropTypes.number,
  activeStep: PropTypes.number,
  totalStepNumber: PropTypes.number,
  goForward: PropTypes.func,
  goForwardText: PropTypes.string,
  disableForward: PropTypes.bool,
  parentTag: PropTypes.string,
  cancel: PropTypes.func,
  cancelText: PropTypes.string,
  goBack: PropTypes.func,
  goBackText: PropTypes.string,
  children: PropTypes.node,
  hideButtons: PropTypes.bool,
  hideContinueButton: PropTypes.bool,
  hideBackButton: PropTypes.bool
};

export default StepForm;
