import React from "react";
import PropTypes from "prop-types";

const Chart = ({ color = "#fff" }) => (
  <svg
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6522 22.4348C14.7913 22.4348 14.087 21.7304 14.087 20.8696V1.56522C14.087 0.704348 14.7913 0 15.6522 0C16.513 0 17.2174 0.704348 17.2174 1.56522V20.8696C17.2174 21.7304 16.513 22.4348 15.6522 22.4348ZM10.1739 20.8696V7.93044C10.1739 7.06957 9.46956 6.36522 8.60869 6.36522C7.74782 6.36522 7.04348 7.06957 7.04348 7.93044V20.8696C7.04348 21.7304 7.74782 22.4348 8.60869 22.4348C9.46956 22.4348 10.1739 21.7304 10.1739 20.8696ZM3.13043 20.8696V14.2957C3.13043 13.4348 2.42609 12.7304 1.56522 12.7304C0.704348 12.7304 0 13.4348 0 14.2957V20.8696C0 21.7304 0.704348 22.4348 1.56522 22.4348C2.42609 22.4348 3.13043 21.7304 3.13043 20.8696Z"
      fill={color}
    />
  </svg>
);

Chart.propTypes = {
  color: PropTypes.string
};

export default Chart;
