import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import { PLANS } from "./config";

import StepForm from "Components/setup/StepForm";
import Heading4 from "Components/styleguide/Heading4";
import StepHeader from "Components/setup/StepHeader";
import StepContent from "Components/setup/StepContent";
import AddOnsInfo from "Components/AddOnsInfo";
import PlanContent from "Components/PlanContent";
import PlanInfo from "Components/setup/PlanInfo";

const Layout = styled.div`
  margin-top: -40px;
  > div {
    max-width: 100%;
    margin-bottom: 30px;
  }
  @media (min-width: 960px) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    > div {
      margin-bottom: 0;
    }
  }
`;

const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  position: relative;
  border-radius: 24px;
  &:focus {
    outline: none;
    &::-webkit-slider-thumb {
      background: radial-gradient(
        closest-side,
        ${props => props.theme.buttonBg} 0,
        ${props => props.theme.buttonBg} 33%,
        ${props => props.theme.buttonBg} 33%,
        #fff 38%,
        #fff 38%,
        #fff 88%,
        #fff 92%,
        ${props => props.theme.buttonBg} 100%
      );
    }
  }
  &::-webkit-slider-thumb {
    background: #fff;
    display: inline-block;
    -webkit-appearance: none;
    outline: none;
    background: radial-gradient(
      closest-side,
      ${props => props.theme.buttonBg} 0,
      ${props => props.theme.buttonBg} 33%,
      ${props => props.theme.buttonBg} 33%,
      #fff 38%,
      #fff 38%,
      #fff 100%
    );
    box-shadow: 0 1px 4px rgba(74, 73, 94, 0.4);
    height: 24px;
    width: 24px;
    border-radius: 24px;
    z-index: 2;
    margin-top: -4px;
  }
  &::-webkit-slider-runnable-track {
    display: inline-block;
    position: relative;
    height: 16px;
    width: 100%;
    left: 0;
    border-radius: 16px;
    background: linear-gradient(
      to right,
      ${props => props.theme.buttonBg} 0%,
      ${props => props.theme.buttonBg} ${props => props.rangeLevel}%,
      #fff ${props => props.rangeLevel}%,
      #fff 100%
    );
  }
  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
`;

const SliderLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 56px;
  padding-top: 8px;
  .label {
    width: 24px;
    text-align: center;
    color: #98a0ab;
    font-size: 14px;
    font-weight: 600;
    top: 14px;
    cursor: pointer;
    &.active,
    &:focus {
      outline: none;
      color: ${props => props.theme.links};
    }
  }
`;

const ScaleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  h4 {
    text-align: center;
    margin: 0 0 40px 0;
  }
`;

const Price = styled.div`
  font-size: 56px;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 16px;
  min-height: 76px;
`;

const SidebarWrapper = styled.aside`
  background: #f1f1f4;
  padding: 40px 40px 80px;
  box-sizing: border-box;
  color: #38485e;
  @media (min-width: 1010px) {
    width: 402px;
    padding: 200px 40px 40px;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
`;

const RightSectionWrapper = styled.div`
  color: #38485e;
  display: flex;
  flex-direction: column-reverse;
  flex-start: flex-end;
  @media (min-width: 1010px) {
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
  }
`;

class PlanStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: "$",
      vendor: process.env.VENDOR_URL ? true : false,
      slider: {
        ...PLANS[1]
      },
      development: {
        label: "Dev",
        price: 10,
        size: "development",
        title: "Development"
      },
      devSelected: false,
      estimate: {
        plan: {},
        environments: {},
        storage: {},
        total: {},
        options: {}
      }
    };

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateEstimation = this.updateEstimation.bind(this);
    this.initiateTrial = this.initiateTrial.bind(this);
  }

  componentDidMount() {
    this.props.getOptions({ ...PLANS[0] });
    if (this.props.plan && this.props.plan.size !== "development") {
      this.setState({
        slider: {
          ...this.props.plan
        }
      });
    }
    if (this.props.plan && this.props.plan.size === "development") {
      this.setState({
        devSelected: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.value) {
      this.setState({
        value: nextProps.value
      });
    }
    this.updateEstimation();
  }

  goForward() {
    this.props.goForward({
      plan: {
        price: this.state.estimate.plan,
        ...this.state.slider
      },
      estimate: { ...this.state.estimate }
    });
  }

  goBack() {
    this.props.goBack(this.state);
  }

  handleChange(value) {
    const plan = value.target
      ? PLANS[value.target.value - 1]
      : PLANS[value - 1];
    this.updateEstimation();
    this.setState({
      slider: {
        ...plan
      },
      devSelected: false
    });

    this.props.getOptions(plan);
  }

  async updateEstimation() {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const estimateToSave = await client.getSubscriptionEstimate(
      this.state.slider.size ? this.state.slider.size : this.props.plan.size,
      "5",
      "3",
      "1",
      "complex"
    );

    this.setState({ estimate: { ...estimateToSave } });
  }

  initiateTrial() {
    this.setState({
      slider: {
        ...PLANS[0]
      }
    });
  }

  render() {
    const {
      activeStep,
      totalSteps,
      step,
      intl,
      theme,
      plan,
      options
    } = this.props;
    const pricingLabels = PLANS.reduce((acc, current, i) => {
      acc[i + 1] =
        current.label === this.state.slider.label ? (
          <a
            href
            className="label active"
            tabIndex="0"
            onClick={() => {
              this.handleChange(i + 1);
            }}
            onKeyUp={e => e.keyCode === 13 && this.handleChange(i + 1)}
          >
            {current.label}
          </a>
        ) : (
          <a
            href
            className="label"
            tabIndex="0"
            onClick={() => {
              this.handleChange(i + 1);
            }}
            onKeyUp={e => e.keyCode === 13 && this.handleChange(i + 1)}
          >
            {current.label}
          </a>
        );
      return acc;
    }, {});

    const pricingLabelLinks = Object.values(pricingLabels).map(label => label);

    const showPlanSlider = options && options.loaded && !options.isTrial;
    if (
      options &&
      options.isTrial &&
      this.state.slider.size !== "development"
    ) {
      this.initiateTrial();
    }

    return (
      <RightSectionWrapper>
        <SidebarWrapper>
          <div className="scale-wrapper">
            {showPlanSlider && (
              <ScaleContent>
                {!this.state.vendor && (
                  <Price className="price-wrapper">
                    <span className="price">
                      {this.state.estimate.plan.formatted}
                    </span>
                  </Price>
                )}
                <Heading4
                  id="plan-name"
                  className="subtitle"
                  data-sku={this.state.slider.sku}
                >
                  {this.state.slider.label === "D"
                    ? ""
                    : intl.formatMessage({ id: "professional" }) + ": "}
                  {this.state.slider.title}
                </Heading4>
                <LiveMessage
                  message={`${
                    this.state.slider.label === "D"
                      ? ""
                      : intl.formatMessage({ id: "professional" }) + ": "
                  }
                  ${this.state.slider.title} for ${
                    this.state.estimate.plan.formatted
                  }`}
                  aria-live="polite"
                />
                <div>
                  <Slider
                    className="plan-slider"
                    type="range"
                    name="environments"
                    value={this.state.slider.value}
                    step="1"
                    min="1"
                    max={PLANS.length}
                    aria-valuemin="1"
                    aria-valuemax={PLANS.length}
                    aria-valuenow="1"
                    onInput={this.handleChange}
                    rangeLevel={
                      ((this.state.slider.value - 1) / (PLANS.length - 1)) * 100
                    }
                  />
                  <SliderLabels className="slider-labels">
                    {pricingLabelLinks}
                  </SliderLabels>
                </div>
                <PlanInfo
                  plan={this.state.slider.label}
                  title={this.state.slider.title}
                  subtitles={true}
                  theme={theme}
                />
              </ScaleContent>
            )}
          </div>
        </SidebarWrapper>
        <MainWrapper>
          <StepHeader
            title="Plan size"
            currentStep={step}
            totalStepNumber={totalSteps}
          >
            Select a plan that’s right for you,
          </StepHeader>
          <StepContent>
            <StepForm
              step={step}
              activeStep={activeStep}
              goForward={this.goForward}
              disableForward={plan || this.state.slider ? false : true}
              goBack={this.goBack}
              totalStepNumber={totalSteps}
            >
              <Layout>
                <PlanContent vendor={this.state.vendor} theme={theme} />
              </Layout>
              <AddOnsInfo
                vendor={this.state.vendor}
                estimate={this.state.estimate}
              />
            </StepForm>
          </StepContent>
        </MainWrapper>
      </RightSectionWrapper>
    );
  }
}

PlanStep.propTypes = {
  initialization: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  goForward: PropTypes.func,
  getOptions: PropTypes.func,
  goBack: PropTypes.func,
  plan: PropTypes.object,
  options: PropTypes.string
};

export default withTheme(injectIntl(PlanStep));
