import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import StepBreadCrumbs from "./StepBreadCrumbs";

const Layout = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
`;

const StepLayout = styled.main`
  background: #fff;
  width: 100%;
  &.step-account,
  &.step-template,
  &.step-info {
    @media (min-width: 1009px) {
      .header-wrapper > div,
      .content-wrapper > div {
        padding-left: 32px;
        padding-right: 32px;
      }
      .header-wrapper > div > div,
      .content-wrapper > div > div {
        max-width: 585px;
        margin: 0 auto;
      }
    }
    @media (min-width: 1252px) {
      .header-wrapper > div > div,
      .content-wrapper > div > div {
        max-width: 889px;
      }
    }
    @media (min-width: 1560px) {
      .header-wrapper > div > div,
      .content-wrapper > div > div {
        max-width: 1196px;
      }
    }
    @media (min-width: 1867px) {
      .header-wrapper > div > div,
      .content-wrapper > div > div {
        max-width: 1503px;
      }
    }
  }
  &.step-configuration {
    @media (min-width: 1250px) {
      .header-wrapper > div,
      .content-wrapper > div {
        padding-right: 92px;
      }
    }
  }
`;

class Stepper extends React.Component {
  constructor(props) {
    super(props);

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.setStepInfo = this.setStepInfo.bind(this);
    this.state = {
      account: "",
      info: "",
      template: "",
      configuration: "",
      region: ""
    };
  }

  componentDidMount() {
    if (this.props.info) {
      this.setState({
        info: this.props.info
      });
    }
    if (this.props.template) {
      this.setState({
        info: this.props.template
      });
    }
    if (this.props.region) {
      this.setState({
        info: this.props.region
      });
    }
    if (this.props.account) {
      this.setState({
        account: this.props.account
      });
    }
  }

  setStepInfo(key, value) {
    this.setState({
      [key]: value
    });
  }

  goForward(formResult) {
    const { names, currentStepName } = this.props;

    this.props.goForward(formResult, names[names.indexOf(currentStepName) + 1]);
  }

  goBack() {
    const { names, currentStepName } = this.props;

    this.props.goBack(names[names.indexOf(currentStepName) - 1]);
  }

  render() {
    const {
      currentStep,
      currentStepName,
      finish,
      components,
      labels,
      totalStepNumber,
      getOptions,
      billing,
      info,
      plan,
      region,
      template,
      account,
      accountId,
      redirectUrl,
      options,
      estimate
    } = this.props;

    const CurrentStepComponent = components[currentStep];

    if (!CurrentStepComponent) {
      return <div>Unknown step</div>;
    }
    return (
      <Layout>
        <StepBreadCrumbs
          activeStep={currentStep + 1}
          totalSteps={totalStepNumber}
          steps={labels}
          names={this.props.names}
          activeStepName={currentStepName}
          goToStep={this.props.goToStep}
          info={info}
          plan={plan}
          region={region}
          template={template}
          account={account}
          estimate={estimate}
        />
        <StepLayout
          id="main"
          role="main"
          className={`main step step-${currentStepName}`}
        >
          <CurrentStepComponent
            step={currentStep}
            getOptions={getOptions}
            activeStep={currentStep}
            totalSteps={totalStepNumber}
            goForward={this.goForward}
            goBack={this.goBack}
            goForwardText="Save & Continue"
            finish={finish}
            billing={billing}
            info={info}
            plan={plan}
            region={region}
            template={template}
            account={account}
            accountId={accountId}
            redirectUrl={redirectUrl}
            options={options}
            estimate={estimate}
          />
        </StepLayout>
      </Layout>
    );
  }
}

Stepper.propTypes = {
  components: PropTypes.array,
  labels: PropTypes.array,
  currentStep: PropTypes.number,
  totalStepNumber: PropTypes.number,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  goToStep: PropTypes.func,
  getOptions: PropTypes.func,
  finish: PropTypes.func,
  names: PropTypes.array,
  currentStepName: PropTypes.string,
  billing: PropTypes.object,
  info: PropTypes.object,
  plan: PropTypes.object,
  region: PropTypes.object,
  template: PropTypes.object,
  account: PropTypes.string,
  accountId: PropTypes.string,
  redirectUrl: PropTypes.string,
  options: PropTypes.object,
  estimate: PropTypes.object
};

export default injectIntl(Stepper);
