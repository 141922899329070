import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { LiveMessage } from "react-aria-live";
import { Link } from "react-router";

import Heading2 from "Components/styleguide/Heading2";

const Layout = styled.div`
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  &.dropshadow {
    box-shadow: 0 0 30px rgba(201, 208, 228, 0.5);
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    > div > div {
      border: none;
    }
  }
  p {
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const CounterWrapper = styled.div`
  margin-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .step-counter {
    color: ${props => props.theme.links};
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .exit {
    cursor: pointer;
    color: #38485e;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    position: absolute;
    right: 40px;
  }
`;

const HeaderWrapper = styled.div`
  max-width: 100%;
  padding: 16px;
  h2 {
    margin-bottom: 16px;
  }
  .description {
    font-size: 15px;
    font-weight: normal;
    line-height: 19px;
    color: #38485e;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  @media (min-width: 600px) {
    padding: 32px;
  }
  @media (min-width: 960px) {
    padding: 40px 64px 0 64px;
    box-sizing: border-box;
  }
  @media (min-width: 1250px) {
    padding: 40px 126px 0 126px;
  }
`;

const StepHeader = ({
  title,
  currentStep,
  totalStepNumber,
  children,
  className
}) => (
  <Layout className={`header-wrapper${className ? " " + className : ""}`}>
    <HeaderWrapper>
      <LiveMessage
        message={`${title}, step ${currentStep + 1} of ${totalStepNumber}`}
        aria-live="polite"
      />
      <div>
        <CounterWrapper className="step-counter-wrapper">
          <span className="step-counter">
            {currentStep + 1 === totalStepNumber ? (
              title
            ) : (
              <FormattedMessage
                id="step_x_of_n"
                values={{
                  currentStep: currentStep + 1,
                  totalStep: totalStepNumber
                }}
              />
            )}
          </span>
          <Link id="exit-project-setup" className="exit" to="/">
            Exit
          </Link>
        </CounterWrapper>
        {currentStep + 1 !== totalStepNumber && <Heading2>{title}</Heading2>}
        {children && <div className="description">{children}</div>}
      </div>
    </HeaderWrapper>
  </Layout>
);

StepHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  currentStep: PropTypes.number,
  totalStepNumber: PropTypes.number,
  className: PropTypes.string
};

export default StepHeader;
