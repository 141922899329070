import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import StepForm from "Components/setup/StepForm";
import StepHeader from "Components/setup/StepHeader";
import StepContent from "Components/setup/StepContent";
import Loading from "Components/Loading";
import Heading2 from "Components/styleguide/Heading2";
import { Link } from "react-router";
import Redirect from "Components/Redirect";
import BuildingProject from "Components/illustrations/BuildingProject";

const LoadingWrapper = styled.div`
  text-align: center;
  color: #38485e;
  h2 {
    margin-bottom: 16px;
  }
  p {
    line-height: 25px;
    font-size: 15px;
  }
  .icon-loading {
    width: 80px;
    height: auto;
    margin-bottom: 30px;
  }
  .graphic {
    margin-bottom: 40px;
  }
  a {
    color: ${props => props.theme.links};
  }
`;

class BuildingStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.props.finish();
  }

  goForward() {
    this.props.goForward({ info: this.state });
  }

  goBack() {
    this.props.goBack(this.state);
  }

  render() {
    const { intl, activeStep, totalSteps, step } = this.props;

    return (
      <div>
        <StepHeader
          title={intl.formatMessage({ id: "building" })}
          currentStep={activeStep}
          totalStepNumber={totalSteps}
        />
        <StepContent>
          {this.props.redirectUrl && <Redirect to={this.props.redirectUrl} />}
          <StepForm
            step={step}
            activeStep={activeStep}
            goForward={this.goForward}
            goBack={this.goBack}
            hideButtons={true}
          >
            <LoadingWrapper>
              <div className="graphic">
                <BuildingProject />
              </div>
              <Heading2>
                {intl.formatMessage({ id: "building_description" })}
              </Heading2>
              <p>
                <Link to="/projects">
                  {intl.formatMessage({ id: "back_to_projects" })}
                </Link>
              </p>
              <Loading iconOnly={true} />
            </LoadingWrapper>
          </StepForm>
        </StepContent>
      </div>
    );
  }
}

BuildingStep.propTypes = {
  initialization: PropTypes.object,
  intl: PropTypes.object,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  info: PropTypes.object,
  finish: PropTypes.func,
  redirectUrl: PropTypes.string
};

export default injectIntl(BuildingStep);
