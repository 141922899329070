import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import Description from "Components/fields/Description";
import Heading3 from "Components/styleguide/Heading3";

const Sidebar = styled.div`
  padding-top: 16px;
  h3 {
    margin-bottom: 10px;
    marign-top: 0;
    font-size: 15px;
    font-weight: 600;
  }
  .description {
    color: #4a495e;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

const InfoItem = styled.div`
  border-bottom: 1px solid #c9d0e4;
  padding-bottom: 16px;
  margin-bottom: 16px;
  font-size: 15px;
`;

const CheckItemWrapper = styled.div`
  @media (min-width: 1250px) {
    column-count: 2;
    column-gap: 32px;
  }
`;

const CheckItem = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  span {
    margin-right: 16px;
  }
`;

class PlanSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { intl, vendor } = this.props;
    return (
      <Sidebar>
        <InfoItem>
          {!vendor && (
            <InfoItem>
              <Heading3>{intl.formatMessage({ id: "free_trial" })}</Heading3>
              <Description className="description">
                {intl.formatMessage({ id: "free_trial_info" })}
              </Description>
            </InfoItem>
          )}
          <Heading3>{intl.formatMessage({ id: "all_include" })}</Heading3>
          <CheckItemWrapper>
            <CheckItem>
              <span>&bull;</span> {intl.formatMessage({ id: "1_website" })}
            </CheckItem>
            <CheckItem>
              <span>&bull;</span> {intl.formatMessage({ id: "3_dev" })}
            </CheckItem>
            <CheckItem>
              <span>&bull;</span>{" "}
              {intl.formatMessage({ id: "unlimited_traffic" })}
            </CheckItem>
            <CheckItem>
              <span>&bull;</span>{" "}
              {intl.formatMessage({ id: "multiple_domains" })}
            </CheckItem>
            <CheckItem>
              <span>&bull;</span>{" "}
              {intl.formatMessage({ id: "storage_per_environment" })}
            </CheckItem>
            <CheckItem>
              <span>&bull;</span> {intl.formatMessage({ id: "1_user" })}
            </CheckItem>
            <CheckItem>
              <span>&bull;</span>{" "}
              {intl.formatMessage({ id: "automatically_provisioned" })}
            </CheckItem>
            <CheckItem>
              <span>&bull;</span>{" "}
              {intl.formatMessage({ id: "micro_service_support" })}
            </CheckItem>
          </CheckItemWrapper>
        </InfoItem>
      </Sidebar>
    );
  }
}

PlanSidebar.propTypes = {
  intl: PropTypes.object,
  vendor: PropTypes.bool,
  theme: PropTypes.object
};

export default injectIntl(PlanSidebar);
