import React from "react";
import PropTypes from "prop-types";

const Account = ({ color = "#fff" }) => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill-target"
      d="M8.45119 0C10.8456 0 12.6765 1.83099 12.6765 4.22536C12.6765 6.61973 10.8456 8.45071 8.45119 8.45071C6.05682 8.45071 4.22583 6.61973 4.22583 4.22536C4.22583 1.83099 6.05682 0 8.45119 0Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill-target"
      d="M8.45071 20C4.92958 20 1.83099 18.169 0 15.4929C0 12.676 5.63381 11.1267 8.45071 11.1267C11.2676 11.1267 16.9014 12.676 16.9014 15.4929C15.0704 18.169 11.9718 20 8.45071 20Z"
      fill={color}
    />
  </svg>
);

Account.propTypes = {
  color: PropTypes.string
};

export default Account;
