import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AccountStep from "Components/setup/steps/account";
import TemplateStep from "Components/setup/steps/template";
import PlanStep from "Components/setup/steps/plan";
import RegionStep from "Components/setup/steps/region/Step";
import InfoStep from "Components/setup/steps/info";
import PaymentStep from "Components/setup/steps/payment";
import BuildingStep from "Components/setup/steps/building";
import Stepper from "Components/setup/Stepper";
import { SUBSCRIPTION_DEFAULTS } from "Components/setup/steps/plan/config";

const STEPS = {
  components: [
    AccountStep,
    InfoStep,
    TemplateStep,
    PlanStep,
    RegionStep,
    PaymentStep,
    BuildingStep
  ],
  labels: [
    "Create account",
    "Create project",
    "Set code base",
    "Plan size",
    "Project region",
    "Billing & Summary",
    "Building"
  ],
  names: [
    "account",
    "info",
    "template",
    "configuration",
    "region",
    "payment",
    "building"
  ]
};

const StepperWrapper = styled.div`
  position: absolute;
  padding: 0 !important;
  margin: 0 !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  > div {
    min-height: 100%;
  }
`;

class SetupSteps extends React.Component {
  constructor(props) {
    super(props);
    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.finishStep = this.finishStep.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cancel = this.cancel.bind(this);

    this.state = {
      setupForm: "",
      step: props.step || "info",
      setupTitle: "",
      isModalOpen: props.isModalOpenDefault || false,
      account: ""
    };
  }
  componentDidMount() {
    // If NavBar loads, make sure these are removed.
    const preLoadingContainer = document.getElementById("pre-loading");
    const fallbackContainer = document.getElementById("fallback");
    if (preLoadingContainer) {
      preLoadingContainer.parentNode.removeChild(preLoadingContainer);
    }

    if (fallbackContainer) {
      fallbackContainer.parentNode.removeChild(fallbackContainer);
    }

    this.props.initializeSetup();
  }

  openModal(modalContent) {
    this.setState({
      isModalOpen: true,
      modalContent
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  getStepUrl(url, stepName) {
    if (url.endsWith("create-project")) {
      return `${url}/${stepName}`;
    } else if (url.endsWith("create-project/")) {
      return `${url}${stepName}`;
    }

    const matches = url.split("/");

    return `/${matches[1]}/${matches[2]}/${stepName}`;
  }

  goForward(formResult, nextStepName) {
    this.props.push(this.getStepUrl(this.props.pathname, nextStepName));
    this.props.goForward(formResult, nextStepName);
  }

  goBack(previousStepName) {
    this.props.push(this.getStepUrl(this.props.pathname, previousStepName));
    this.props.goBack(previousStepName);
  }

  goToStep(step) {
    this.props.goToStep(step);
  }

  finishStep() {
    // Create the new subscription.

    const setup = this.props.setup.toJS();
    const region = setup.region.id;
    const plan = setup.plan.size;
    const title = setup.info.projectTitle;
    const optionsUrl = setup.template.template;
    const storage = SUBSCRIPTION_DEFAULTS.storage;
    const environments = SUBSCRIPTION_DEFAULTS.environments;

    this.props.finalizeSubscription(
      region,
      plan,
      title,
      storage,
      environments,
      optionsUrl
    );
  }

  cancel() {
    this.setState({
      setupForm: "",
      setupTitle: "",
      isModalOpen: false
    });
  }

  isDebug() {
    return window.location.href.search("[?&]debug=") != -1;
  }

  render() {
    const setup = this.props.setup.toJS();
    const { getOptions } = this.props;

    const meData = this.props.me.get("data");
    const account = meData && meData.toJS().display_name;
    const accountId = meData && meData.toJS().uuid;

    const filteredSteps = (value = "payment") => {
      const roles = meData && meData.get("roles") && meData.get("roles").toJS();
      const accountIndex = STEPS.names.indexOf("account");
      if (
        process.env.VENDOR_URL ||
        (roles && roles.indexOf("team member") > -1 && this.isDebug() === false)
      ) {
        const stepIndex = STEPS.names.indexOf(value);
        return {
          components: STEPS.components.filter(
            (el, i) =>
              roles ? i !== stepIndex && i !== accountIndex : i !== stepIndex
          ),
          labels: STEPS.labels.filter(
            (el, i) =>
              roles ? i !== stepIndex && i !== accountIndex : i !== stepIndex
          ),
          names: STEPS.names.filter(
            (el, i) =>
              roles ? i !== stepIndex && i !== accountIndex : i !== stepIndex
          )
        };
      } else {
        if (account) {
          const stepIndex = accountIndex;
          return {
            components: STEPS.components.filter((el, i) => i !== stepIndex),
            labels: STEPS.labels.filter((el, i) => i !== stepIndex),
            names: STEPS.names.filter((el, i) => i !== stepIndex)
          };
        } else {
          return STEPS;
        }
      }
    };
    const stepType = filteredSteps();

    const stepIndex = stepType.names.indexOf(setup.step);

    return (
      <StepperWrapper className="step-wrapper">
        {stepType && (
          <Stepper
            totalStepNumber={stepType.components.length}
            currentStep={stepIndex}
            currentStepName={setup.step}
            goForward={this.goForward}
            goBack={this.goBack}
            goToStep={this.goToStep}
            getOptions={getOptions}
            finish={this.finishStep}
            billing={setup.billing}
            info={setup.info}
            plan={setup.plan}
            region={setup.region}
            template={setup.template}
            redirectUrl={this.props.redirectUrl}
            account={account}
            accountId={accountId}
            options={setup.options}
            estimate={setup.estimate}
            {...stepType}
          />
        )}
      </StepperWrapper>
    );
  }
}

SetupSteps.propTypes = {
  intl: PropTypes.object,
  projectId: PropTypes.string,
  step: PropTypes.string,
  pathname: PropTypes.string,
  project: PropTypes.object,
  initialize: PropTypes.func,
  getOptions: PropTypes.func,
  push: PropTypes.func,
  isModalOpenDefault: PropTypes.bool,
  redirectUrl: PropTypes.string,
  finalizeSubscription: PropTypes.func,
  initializeSetup: PropTypes.func,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  goToStep: PropTypes.func,
  setup: PropTypes.object,
  me: PropTypes.object
};

const mapStateToProps = state => {
  return {
    me: state.app.get("me"),
    setup: state.setup
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goForward: (formResult, nextStepName) =>
      import("Reducers/project/setup").then(reducer =>
        dispatch(reducer.goForward(formResult, nextStepName))
      ),
    goBack: nextStepName =>
      import("Reducers/project/setup").then(reducer =>
        dispatch(reducer.goBack(nextStepName))
      ),
    goToStep: stepName =>
      import("Reducers/project/setup").then(reducer =>
        dispatch(reducer.goToStep(stepName))
      ),
    initializeSetup: () =>
      import("Reducers/project/setup").then(reducer =>
        dispatch(reducer.initializeSetup())
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupSteps);
