import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { initialize } from "Reducers/environment";
import logger from "Libs/logger";
import client from "Libs/platform";
import SetupSteps from "Components/setup";

const Layout = styled.div`
  display: flex;

  @media (max-device-width: 900px), (max-width: 900px) {
    display: flex;
    flex-direction: column-reverse;

    & > div {
      width: 90vw;
      margin: 0;
      margin-left: 2vw;
      padding: 32px;
    }
  }
`;

class ProjectSetup extends React.Component {
  constructor(props) {
    super(props);

    this.getOptions = this.getOptions.bind(this);
    this.finalizeSubscription = this.finalizeSubscription.bind(this);
    this.stopFinalizeSubscription = this.stopFinalizeSubscription.bind(this);
    this.getSubscription = this.getSubscription.bind(this);

    this.state = {
      subscriptionHasTimedOut: false
    };
  }

  componentWillUnmount() {
    clearInterval(this.subscriptionTimeout);
    this.props.resetSetup();
  }

  stopFinalizeSubscription() {
    this.setState({
      subscriptionHasTimedOut: true
    });
    clearInterval(this.subscriptionTimeout);
  }

  getOptions(plan) {
    this.props.getOptions(plan);
  }

  async finalizeSubscription(
    region,
    plan,
    title,
    storage,
    environments,
    optionsUrl
  ) {
    this.subscriptionTimeout = setInterval(
      () => this.stopFinalizeSubscription(),
      900000 // 15mins
    );

    //
    // somewhere in this we need to reload /me ....
    //

    const vendorName = process.env.VENDOR_NAME || undefined;
    try {
      const newSubscription = await client.createSubscription(
        region,
        plan,
        title,
        storage,
        environments,
        optionsUrl,
        vendorName
      );
      const subscriptionId = newSubscription.data.id;
      const projectOptions = newSubscription.data.project_options.initialize
        ? newSubscription.data.project_options.initialize
        : {};

      this.getSubscriptionRepeat = setInterval(
        () => this.getSubscription(subscriptionId, projectOptions),
        10000
      );
    } catch (error) {
      logger(error);
    }
  }

  async getSubscription(id, options) {
    if (this.state.subscriptionHasTimedOut === false) {
      try {
        const subscription = await client.getSubscription(id);

        // If the project setup is complete proceed to the next
        // steps in the workflow.
        if (subscription["status"] === "active") {
          // @todo: possible remove this because we may not need
          // any project info outside the redirect.
          const redirectUrl = `/${subscription.owner_info.username}/${
            subscription.project_id
          }`;

          const redirect = () => {
            this.setState({
              redirectUrl
            });
            clearInterval(this.getSubscriptionRepeat);
          };

          // If there are no options listed skip the initalization process.
          if (Object.keys(options).length > 0) {
            redirect();
          }

          client
            .initializeEnvironment(subscription.project_id, options)
            .then(() => {
              redirect();
            })
            .catch(error => {
              logger(error);
              redirect();
            });
        }
      } catch (error) {
        logger(error);
      }
    } else {
      // Stop making the subscription call the timer has expired.
      clearInterval(this.getSubscriptionRepeat);
    }
  }

  render() {
    const {
      organizationId,
      projectId,
      initialize,
      brand,
      step,
      push,
      pathname
    } = this.props;

    return (
      <Layout>
        <SetupSteps
          organizationId={organizationId}
          projectId={projectId}
          brand={brand}
          step={step}
          initialize={initialize}
          push={push}
          pathname={pathname}
          getOptions={this.getOptions}
          finalizeSubscription={this.finalizeSubscription}
          getSubscription={this.getSubscription}
          vendorOptions={this.state}
          redirectUrl={this.state.redirectUrl}
        />
      </Layout>
    );
  }
}

ProjectSetup.propTypes = {
  push: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  organizationId: PropTypes.string,
  brand: PropTypes.string,
  step: PropTypes.string,
  pathname: PropTypes.string,
  loadRegion: PropTypes.func,
  initialize: PropTypes.func,
  resetSetup: PropTypes.func,
  getOptions: PropTypes.func
};

const mapStateToProps = state => ({
  userId: state.app.get("me", {}).id
});

const mapDispatchToProps = (dispatch, props) => ({
  initialize: args =>
    dispatch(initialize(props.organizationId, props.projectId, args)),
  getOptions: plan =>
    import("Reducers/project/setup").then(reducer =>
      dispatch(reducer.getOptions(plan))
    ),
  goForward: (formResult, nextStepName) =>
    import("Reducers/project/setup").then(reducer =>
      dispatch(reducer.goForward(formResult, nextStepName))
    ),
  goBack: nextStepName =>
    import("Reducers/project/setup").then(reducer =>
      dispatch(reducer.goBack(nextStepName))
    ),
  goToStep: stepName =>
    import("Reducers/project/setup").then(reducer =>
      dispatch(reducer.goToStep(stepName))
    ),
  initializeSetup: () =>
    import("Reducers/project/setup").then(reducer =>
      dispatch(reducer.initializeSetup())
    ),
  resetSetup: () =>
    import("Reducers/project/setup").then(reducer =>
      dispatch(reducer.resetSetup())
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSetup);
