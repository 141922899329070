import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import StepForm from "Components/setup/StepForm";
import InfoForm from "Components/setup/steps/info/Form";
import StepHeader from "Components/setup/StepHeader";
import StepContent from "Components/setup/StepContent";

class InfoStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectTitle: ""
    };

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onInfoChange = this.onInfoChange.bind(this);
  }

  componentDidMount() {
    const info = this.props.info;
    if (info) {
      this.setState({
        projectTitle: info.projectTitle
      });
    }
  }

  goForward() {
    this.props.goForward({ info: this.state });
  }

  goBack() {
    this.props.goBack(this.state);
  }

  onInfoChange(e) {
    this.setState({
      projectTitle: e.target.value
    });
  }

  render() {
    const { intl, activeStep, totalSteps, step, info, account } = this.props;

    return (
      <div>
        <StepHeader
          title={intl.formatMessage({ id: "project_information" })}
          currentStep={activeStep}
          totalStepNumber={totalSteps}
        >
          Name your project to begin
        </StepHeader>
        <StepContent>
          <StepForm
            step={step}
            activeStep={activeStep}
            goForward={this.goForward}
            disableForward={this.state.projectTitle || info ? false : true}
            goBack={this.goBack}
            totalStepNumber={totalSteps}
            hideBackButton={account ? true : false}
          >
            <InfoForm
              onChange={this.onInfoChange}
              projectTitle={this.state.projectTitle}
            />
          </StepForm>
        </StepContent>
      </div>
    );
  }
}

InfoStep.propTypes = {
  initialization: PropTypes.object,
  intl: PropTypes.object,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  info: PropTypes.object,
  account: PropTypes.string
};

export default injectIntl(InfoStep);
