import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import InputField from "Components/fields/InputField";

const Layout = styled.div`
  width: 100%;
`;

class ApplicationConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl, onChange, projectTitle = "" } = this.props;

    return (
      <Layout>
        <InputField
          id="input-project-name"
          label={intl.formatMessage({ id: "project_name" })}
          onChange={onChange}
          value={projectTitle ? projectTitle : ""}
          placeholder="My project"
        />
      </Layout>
    );
  }
}

ApplicationConfigurationForm.propTypes = {
  onChange: PropTypes.func,
  projectTitle: PropTypes.string,
  intl: PropTypes.object
};

export default injectIntl(ApplicationConfigurationForm);
