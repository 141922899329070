import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import UsersIcon from "Icons/UsersIcon";
import EnvironmentsIcon from "Icons/EnvironmentsIcon";
import StorageIcon from "Icons/StorageIcon";
import Description from "Components/fields/Description";
import Heading3 from "Components/styleguide/Heading3";
import Label from "Components/fields/Label";

const AddOns = styled.div`
  margin-bottom: 32px;
  max-width: 520px;
  h3 {
    margin-bottom: 10px;
    margin-top: 0;
    font-size: 15px;
    font-weight: 600;
  }
  .main-description {
    color: #4a495e;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 32px;
  }
`;

const AddOn = styled.div`
  width: 152px;
  text-align: center;
  .image {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  svg {
    width: 26px;
    height: auto;
  }
  .description {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 22px;
    color: #4a495e;
  }
  &.add-on-users svg {
    width: 56px;
  }
  &.add-on-environments svg {
    width: 40px;
  }
  &.add-on-storage svg {
    width: 32px;
  }
`;

const AddOnWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  @media (min-width: 1336px) {
    justify-content: space-between;
  }
`;

class AddOnsInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl,
      vendor,
      estimate: {
        options: { user_licenses, environments, storage }
      }
    } = this.props;
    return (
      <AddOns>
        <Heading3>{intl.formatMessage({ id: "scale_up_down" })}</Heading3>
        <Description className="main-description">
          {intl.formatMessage({ id: "scale_description" })}
        </Description>
        <AddOnWrapper>
          <AddOn className="add-on-users">
            <div className="image">
              <UsersIcon />
            </div>
            <div>
              <Label color="#38485e">
                {intl.formatMessage({ id: "users" })}
              </Label>
              {!vendor && (
                <Description className="description">
                  <FormattedMessage
                    id="users_description"
                    values={{
                      price: user_licenses && user_licenses.formatted
                    }}
                  />
                </Description>
              )}
            </div>
          </AddOn>
          <AddOn className="add-on-environments">
            <div className="image">
              <EnvironmentsIcon />
            </div>
            <div>
              <Label color="#38485e">
                {intl.formatMessage({ id: "environments" })}
              </Label>
              {!vendor && (
                <Description className="description">
                  <FormattedMessage
                    id="environments_description"
                    values={{
                      price: environments && environments.formatted
                    }}
                  />
                </Description>
              )}
            </div>
          </AddOn>
          <AddOn className="add-on-storage">
            <div className="image">
              <StorageIcon />
            </div>
            <div>
              <Label color="#38485e">
                {intl.formatMessage({ id: "storage" })}
              </Label>
              {!vendor && (
                <Description className="description">
                  <FormattedMessage
                    id="storage_description"
                    values={{
                      price: storage && storage.formatted
                    }}
                  />
                </Description>
              )}
            </div>
          </AddOn>
        </AddOnWrapper>
      </AddOns>
    );
  }
}

AddOnsInfo.propTypes = {
  intl: PropTypes.object,
  vendor: PropTypes.bool,
  estimate: PropTypes.object
};

export default injectIntl(AddOnsInfo);
