import React from "react";
import PropTypes from "prop-types";

const Card = ({ color = "#fff" }) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V4H0V2Z"
      fill={color}
    />
    <path
      d="M0 7H24V14C24 15.1046 23.1046 16 22 16H2C0.89543 16 0 15.1046 0 14V7Z"
      fill={color}
    />
  </svg>
);

Card.propTypes = {
  color: PropTypes.string
};

export default Card;
