import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Layout = styled.div`
  padding: 0;
  box-sizing: border-box;
  &.grey-background {
    background: #f7f8f9;
  }
`;

const ContentWrapper = styled.div`
  max-width: 100%;
  padding: 16px 16px 64px;
  .dropdown {
    margin-bottom: 24px;
    .select-box,
    .select-box__control,
    .select-box__value-container,
    .select-box__single-value {
      height: 40px;
      line-height: 38px;
    }
  }
  @media (min-width: 600px) {
    padding: 32px 32px 64px;
  }
  @media (min-width: 960px) {
    padding: 40px 64px 64px 64px;
    box-sizing: border-box;
  }
  @media (min-width: 1250px) {
    padding: 40px 126px 64px 126px;
  }
`;

const StepContent = ({ children, className }) => (
  <Layout className={`content-wrapper${className ? " " + className : ""}`}>
    <ContentWrapper>{children}</ContentWrapper>
  </Layout>
);

StepContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default StepContent;
