import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router";

const Layout = styled.div`
  position: relative;
  color: #1a192b;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 48px 24px 24px;
  box-sizing: border-box;
  position: relative;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(75, 97, 128, 0.32);
  height: 224px;
  transition: all 0.2s;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .image-wrapper {
    position: relative;
    height: 40px;
    width: 100%;
    margin-bottom: 32px;
    .image {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .select-link {
    margin-bottom: 16px;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 224px;
    }
    &:focus {
      outline: none;
    }
  }
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
    max-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .links {
    z-index: 1;
  }
  .link-style {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    padding: 0 8px;
    color: ${props => props.theme.links};
    span {
      position: relative;
      z-index: 1;
    }
    &:focus {
      border: 1px solid ${props => props.theme.links};
      border-radius: 4px;
      outline: none;
    }
  }
  &.platformsh-empty .title {
    line-height: 24px;
    margin-bottom: 20px;
    white-space: normal;
    text-align: center;
    max-width: 180px;
  }
  &:hover,
  &:focus-within {
    cursor: pointer;
    box-shadow: 0 6px 44px rgba(152, 160, 171, 0.6);
    outline: none;
    border-radius: 4px;
    .link-style,
    + button {
      display: inline-block;
    }
  }
  &.active {
    background: ${props => props.theme.buttonBg};
    box-shadow: 0 1px 6px rgba(75, 97, 128, 0.16);
    .title,
    .tag,
    .link-style {
      color: #fff;
    }
    img {
      filter: brightness(0) invert(1);
    }
    .select-link {
      left: 70px;
    }
    .details-link {
      right: 70px;
    }
  }
  &.platformsh-empty {
    padding-top: 56px;
    background-image: repeating-linear-gradient(
        to right,
        #c9d0e4 0%,
        #c9d0e4 50%,
        transparent 50%,
        transparent 100%
      ),
      repeating-linear-gradient(
        to right,
        #c9d0e4 0%,
        #c9d0e4 50%,
        transparent 50%,
        transparent 100%
      ),
      repeating-linear-gradient(
        to bottom,
        #c9d0e4 0%,
        #c9d0e4 50%,
        transparent 50%,
        transparent 100%
      ),
      repeating-linear-gradient(
        to bottom,
        #c9d0e4 0%,
        #c9d0e4 50%,
        transparent 50%,
        transparent 100%
      );
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
    .image-wrapper,
    .title {
      text-align: center;
    }
    .image-wrapper {
      height: 46px;
      margin-bottom: 18px;
      .image {
        height: 46px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

const Base = ({ template, onClick, openModal, activeClass, modalOpen }) => (
  <Layout
    className={`stack${
      template.info.id ? " " + template.info.id.replace(/\//gm, "-") : ""
    }${template.info.id === activeClass ? " active" : ""}`}
  >
    <div className="image-wrapper">
      <div className="image stack-image">
        <img alt={`${template.info.name} logo`} src={template.info.image} />
      </div>
    </div>
    <Link
      id={`template-${template.info.id.replace(/\//gm, "-")}`}
      onClick={() => {
        onClick();
        document.activeElement.blur();
      }}
      onKeyUp={e => e.keyCode === 13 && onClick()}
      className="select-link"
      tabIndex="0"
    >
      <span className="title base-title">{template.info.name}</span>
    </Link>
    <div className="links">
      <Link
        id={`template-${template.info.id.replace(/\//gm, "-")}-detail`}
        onClick={e => {
          e.stopPropagation();
          openModal();
          document.activeElement.blur();
        }}
        onKeyDown={e => {
          if (e.keyCode === 13 && !modalOpen) {
            openModal();
          }
        }}
        className="link-style details-link"
        tabIndex="0"
      >
        <span>Details</span>
      </Link>
    </div>
  </Layout>
);

Base.propTypes = {
  stack: PropTypes.object,
  template: PropTypes.object,
  onClick: PropTypes.func,
  openModal: PropTypes.func,
  activeClass: PropTypes.string,
  modalOpen: PropTypes.bool
};

export default Base;
