import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ProjectSetup from "Containers/ProjectSetup";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

class Setup extends React.Component {
  render() {
    return (
      <Layout>
        <ProjectSetup
          pathname={this.props.location.pathname + this.props.location.search}
          push={this.props.router.push}
          {...this.props.params}
        />
      </Layout>
    );
  }
}

Setup.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    projectId: PropTypes.string,
    organizationId: PropTypes.string,
    brand: PropTypes.string,
    step: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  })
};

export default Setup;
