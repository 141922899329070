import React from "react";
import PropTypes from "prop-types";

const Pin = ({ color = "#fff" }) => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.60869 0C10.8783 0 12.9652 0.730435 14.4522 2.06087C16.2783 3.67826 17.2174 6.10435 17.2174 9.13043C17.2174 15.6783 9.67826 23.3739 9.33913 23.687C9.15652 23.8957 8.86956 24 8.60869 24C8.34782 24 8.08696 23.8957 7.87826 23.687C7.53913 23.3739 0 15.6783 0 9.13043C0 6.10435 0.93913 3.67826 2.76522 2.06087C4.25217 0.730435 6.33913 0 8.60869 0ZM8.5 14C10.9853 14 13 11.9853 13 9.5C13 7.01472 10.9853 5 8.5 5C6.01472 5 4 7.01472 4 9.5C4 11.9853 6.01472 14 8.5 14Z"
      fill={color}
    />
  </svg>
);

Pin.propTypes = {
  color: PropTypes.string
};

export default Pin;
