import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { loadRegions } from "Reducers/project/region";
import Dropdown from "Components/Dropdown";
import Description from "Components/fields/Description";
import InfoDialog from "Components/InfoDialog";

const Layout = styled.div`
  .dropdown {
    display: block;
    margin-bottom: 30px;
    .input-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
    .Select {
      background: #fff;
      border: 1px solid rgba(151, 151, 151, 0.2);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      &.is-focused {
        outline-style: auto;
        outline-color: ${props => props.theme.buttonBg};
      }
    }
    .Select-menu-outer {
      .Select-option {
        padding-left: 2em;
      }
      .Select-option.is-disabled {
        cursor: default;
        padding-left: 1em;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .description {
    display: flex;
    align-items: center;
    .info-dialog {
      margin: 0 12px 0 0;
    }
  }
`;

class RegionPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onChange = this.onChange.bind(this);
    this.checkPremium = this.checkPremium.bind(this);
  }

  componentDidMount() {
    if (this.props.region) {
      this.setState({
        ...this.props.region
      });
    }
    this.props.loadRegion();
  }

  onChange(region) {
    this.props.onChange(region);
    this.setState({
      region
    });
  }

  checkPremium(region) {
    if (region && region.id && region.id.includes("de")) {
      return true;
    }
    return false;
  }

  render() {
    if (!this.props.regions) {
      return false;
    }

    const regions = this.props.regions.toJS();
    const zones = Object.keys(regions);
    let options = [];
    zones.map(zone => {
      let zoneOptions = [];
      regions[zone] &&
        regions[zone].map(region => {
          zoneOptions.push(region);
        });
      options.push({
        label: zone === "" ? "Other" : zone,
        value: zone === "" ? "Other" : zone,
        options: zoneOptions
      });
    });

    return (
      <Layout>
        <Dropdown
          label="Data center region"
          options={options}
          defaultValue={
            !this.state.region && this.props.region
              ? this.props.region
              : this.state.region
          }
          onChange={this.onChange}
          clearable={false}
          error={this.props.errors}
          fieldType={true}
          required={true}
        />
        {this.checkPremium(this.state.region) && (
          <Description className="description">
            <InfoDialog
              title="German region"
              text="Learn more about our strict data locality guarantees, DSGVO (GDPR) and BDSG compliance"
              to="https://docs.platform.sh/overview/pricing.html"
              linkText="Learn more"
            />
            Please note that projects in the German region cost 10% more.
          </Description>
        )}
      </Layout>
    );
  }
}

RegionPicker.propTypes = {
  loadRegion: PropTypes.func,
  onChange: PropTypes.func,
  regions: PropTypes.object,
  errors: PropTypes.object,
  region: PropTypes.object,
  vendor: PropTypes.bool
};

const mapStateToProps = state => ({
  regions: state.region.get("data")
});

const mapDispatchToProps = dispatch => ({
  loadRegion: () => dispatch(loadRegions())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionPicker);
