import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import Templates from "./Templates";
import StepHeader from "Components/setup/StepHeader";
import StepContent from "Components/setup/StepContent";

const CodeBaseWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  .content-wrapper {
    height: 100%;
  }
`;

class TemplateStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onTemplateSelectionChange = this.onTemplateSelectionChange.bind(this);
  }

  componentDidMount() {
    const template = this.props.template;
    if (template) {
      this.setState({
        ...template
      });
    }
  }

  goForward() {
    this.props.goForward({ template: this.state });
  }

  goBack() {
    this.props.goBack(this.state);
  }

  onTemplateSelectionChange(template) {
    this.setState({
      ...template
    });
  }

  render() {
    const { activeStep, step, catalog, totalSteps, template } = this.props;

    return (
      <CodeBaseWrapper>
        <StepHeader
          title="Select your code base template"
          currentStep={activeStep}
          totalStepNumber={totalSteps}
        >
          For your first project please select a template from the options below
          or create your own blank project to use your own .yaml file. For more
          information about each template select ‘Details’ for an overview of
          what’s included.
        </StepHeader>
        <StepContent>
          <Templates
            templates={catalog && catalog.toJS()}
            onChange={this.onTemplateSelectionChange}
            goForward={this.goForward}
            step={step}
            activeStep={activeStep}
            totalStepNumber={totalSteps}
            goBack={this.goBack}
            template={this.state.template ? this.state.template : template}
          />
        </StepContent>
      </CodeBaseWrapper>
    );
  }
}

TemplateStep.propTypes = {
  initialization: PropTypes.object,
  intl: PropTypes.object,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  template: PropTypes.object,
  catalog: PropTypes.object
};
const mapStateToProps = state => {
  return {
    catalog: state.setup.get("catalog")
  };
};

export default connect(mapStateToProps)(injectIntl(TemplateStep));
