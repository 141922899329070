import React from "react";

const BuildingProject = () => (
  <svg
    width="164"
    height="126"
    viewBox="0 0 164 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M151 71H85.2002C84.1002 71 83.2002 71.9 83.2002 73V93.2C83.2002 94.3 84.1002 95.2 85.2002 95.2H151C152.1 95.2 153 94.3 153 93.2V73C153 71.9 152.1 71 151 71ZM89.7002 95C88.0002 95 86.7002 93.7 86.7002 92C86.7002 90.3 88.0002 89 89.7002 89C91.4002 89 92.7002 90.3 92.7002 92C92.7002 93.7 91.3002 95 89.7002 95ZM89.7002 85.1C88.0002 85.1 86.7002 83.8 86.7002 82.1C86.7002 80.4 88.0002 79.1 89.7002 79.1C91.4002 79.1 92.7002 80.4 92.7002 82.1C92.7002 83.7 91.3002 85.1 89.7002 85.1Z"
      fill="#CDDEFF"
    />
    <path
      d="M124.3 35.6H89.8L86.3 27.4C86 26.9 85.5 26.5 84.9 26.5H63.7C62.8 26.5 62 27.2 62 28.2V35.6V35.9V43.2C62 44.1 62.7 44.9 63.7 44.9H124.4C125.3 44.9 126.1 44.2 126.1 43.2V37.3C126 36.3 125.2 35.6 124.3 35.6Z"
      fill="#CDDEFF"
    />
    <path
      d="M135.2 0.8L136.6 2.2L134.5 4.3C134.1 4.6 134.1 5.2 134.5 5.5L134.6 5.6C135 5.9 135.5 5.9 135.8 5.6L137.9 3.5L139.3 4.9C139.6 5.2 140.1 5 140.1 4.6V0.4C140.1 0.1 139.9 0 139.7 0H135.6C135.1 0 134.9 0.4 135.2 0.8ZM130.2 0H126.4C126.1 0 126 0.2 126 0.4V4.2C126 4.6 126.4 4.8 126.8 4.6L128 3.4L131.6 7C131.9 7.3 132.1 7.8 132.1 8.2V13.2C132.1 13.7 132.5 14.1 133 14.1C133.5 14.1 133.9 13.7 133.9 13.2V7.3C133.9 6.9 133.7 6.4 133.4 6.1L129.2 2L130.4 0.8C130.8 0.4 130.6 0 130.2 0Z"
      fill="#C9D0E4"
    />
    <path
      d="M9 70.2H6V67C6 66.4 5.6 66 5 66C4.4 66 4 66.4 4 67V70.2H1C0.4 70.2 0 70.6 0 71.2C0 71.8 0.4 72.2 1 72.2H4V75C4 75.6 4.4 76 5 76C5.6 76 6 75.6 6 75V72.2H9C9.6 72.2 10 71.8 10 71.2C10 70.6 9.6 70.2 9 70.2Z"
      fill="#C9D0E4"
    />
    <path
      d="M36 11.8C35.7 11.8 35.5 11.7 35.3 11.5C34.9 11.1 34.9 10.5 35.3 10.1L38.1 7.3C38.5 6.9 39.1 6.9 39.5 7.3C39.9 7.7 39.9 8.3 39.5 8.7L36.7 11.5C36.5 11.7 36.3 11.8 36 11.8Z"
      fill="#4a495e"
    />
    <path
      d="M27.9999 13.9001C27.8999 13.9001 27.7999 13.9001 27.6999 13.9001C27.1999 13.8001 26.7999 13.2001 26.9999 12.7001L29.9999 1.70011C30.0999 1.20011 30.6999 0.800106 31.1999 1.00011C31.6999 1.10011 32.0999 1.70011 31.8999 2.20011L28.9999 13.1001C28.7999 13.6001 28.3999 13.9001 27.9999 13.9001Z"
      fill="#4a495e"
    />
    <path
      d="M38.1 8.69995L35.3 5.89995C34.9 5.49995 34.9 4.89995 35.3 4.49995C35.7 4.09995 36.3 4.09995 36.7 4.49995L39.5 7.29995L38.1 8.69995Z"
      fill="#4a495e"
    />
    <path
      d="M20 8.99995C19.7 8.99995 19.5 8.89995 19.3 8.69995C18.9 8.29995 18.9 7.69995 19.3 7.29995L22.1 4.49995C22.5 4.09995 23.1 4.09995 23.5 4.49995C23.9 4.89995 23.9 5.49995 23.5 5.89995L20.7 8.69995C20.5 8.89995 20.3 8.99995 20 8.99995Z"
      fill="#4a495e"
    />
    <path
      d="M22.8 11.8C22.5 11.8 22.3 11.7 22.1 11.5L19.3 8.7C18.9 8.3 18.9 7.7 19.3 7.3C19.7 6.9 20.3 6.9 20.7 7.3L23.5 10.1C23.9 10.5 23.9 11.1 23.5 11.5C23.3 11.7 23.1 11.8 22.8 11.8Z"
      fill="#4a495e"
    />
    <path
      d="M89.7002 86.1001C87.5002 86.1001 85.7002 84.3001 85.7002 82.1001C85.7002 79.9001 87.5002 78.1001 89.7002 78.1001C91.9002 78.1001 93.7002 79.9001 93.7002 82.1001C93.7002 84.3001 91.9002 86.1001 89.7002 86.1001ZM89.7002 80.1001C88.6002 80.1001 87.7002 81.0001 87.7002 82.1001C87.7002 83.2001 88.6002 84.1001 89.7002 84.1001C90.8002 84.1001 91.7002 83.2001 91.7002 82.1001C91.7002 81.0001 90.8002 80.1001 89.7002 80.1001Z"
      fill="#4a495e"
    />
    <path
      d="M78 97.2V97C45.6 97.3 20 101.7 20 107C20 112.5 47.8 117 82 117C116.2 117 144 112.5 144 107C144 104.4 137.9 102 127.8 100.3H81C79.3 100.2 78 98.9 78 97.2Z"
      fill="#C9D0E4"
    />
    <path
      d="M160 49.5C161.1 49.5 162 48.6 162 47.5C162 46.4 161.1 45.5 160 45.5C158.9 45.5 158 46.4 158 47.5C158 48.6 158.9 49.5 160 49.5Z"
      fill="#CDDEFF"
    />
    <path
      d="M151 55.5C152.1 55.5 153 54.6 153 53.5C153 52.4 152.1 51.5 151 51.5C149.9 51.5 149 52.4 149 53.5C149 54.6 149.9 55.5 151 55.5Z"
      fill="#4a495e"
    />
    <path
      d="M159.6 51.7001C157.2 51.7001 155.3 49.8001 155.3 47.4001C155.3 45.0001 157.2 43.1001 159.6 43.1001C162 43.1001 163.9 45.0001 163.9 47.4001C163.9 49.8001 162 51.7001 159.6 51.7001ZM159.6 45.0001C158.3 45.0001 157.3 46.0001 157.3 47.3001C157.3 48.6001 158.3 49.6001 159.6 49.6001C160.9 49.6001 161.9 48.6001 161.9 47.3001C161.9 46.0001 160.9 45.0001 159.6 45.0001Z"
      fill="#4a495e"
    />
    <path
      d="M36 82C32.7 82 30 79.3 30 76C30 72.7 32.7 70 36 70C39.3 70 42 72.7 42 76C42 79.3 39.3 82 36 82ZM36 72C33.8 72 32 73.8 32 76C32 78.2 33.8 80 36 80C38.2 80 40 78.2 40 76C40 73.8 38.2 72 36 72Z"
      fill="#C9D0E4"
    />
    <path
      d="M36 65C32.7 65 30 62.3 30 59C30 55.7 32.7 53 36 53C39.3 53 42 55.7 42 59C42 62.3 39.3 65 36 65ZM36 55C33.8 55 32 56.8 32 59C32 61.2 33.8 63 36 63C38.2 63 40 61.2 40 59C40 56.8 38.2 55 36 55Z"
      fill="#C9D0E4"
    />
    <path
      d="M36 48C32.7 48 30 45.3 30 42C30 38.7 32.7 36 36 36C39.3 36 42 38.7 42 42C42 45.3 39.3 48 36 48ZM36 38C33.8 38 32 39.8 32 42C32 44.2 33.8 46 36 46C38.2 46 40 44.2 40 42C40 39.8 38.2 38 36 38Z"
      fill="#C9D0E4"
    />
    <path
      d="M89.7002 96C87.5002 96 85.7002 94.2 85.7002 92C85.7002 89.8 87.5002 88 89.7002 88C91.9002 88 93.7002 89.8 93.7002 92C93.7002 94.2 91.9002 96 89.7002 96ZM89.7002 90C88.6002 90 87.7002 90.9 87.7002 92C87.7002 93.1 88.6002 94 89.7002 94C90.8002 94 91.7002 93.1 91.7002 92C91.7002 90.9 90.8002 90 89.7002 90Z"
      fill="#4a495e"
    />
    <path
      d="M147 74.2H81C79.3 74.2 78 75.5 78 77.2V85.5H56.7C56.3 85.5 56 85.2 56 84.8V50.5H118V71H120V41.3C120 39.8 118.8 38.6 117.3 38.6H83.5L80.2 30.9C79.7 30 78.8 29.5 77.9 29.5H56.7C55.2 29.5 54 30.7 54 32.2V41H47C46.4 41 46 41.4 46 42C46 42.6 46.4 43 47 43H54V58H47C46.4 58 46 58.4 46 59C46 59.6 46.4 60 47 60H54V75H47C46.4 75 46 75.4 46 76C46 76.6 46.4 77 47 77H54V84.8C54 86.3 55.2 87.5 56.7 87.5H78V97V97.2C78 98.9 79.3 100.2 81 100.2H127.8H147C148.7 100.2 150 98.9 150 97.2V77.2C150 75.5 148.7 74.2 147 74.2ZM56 32.2C56 31.8 56.3 31.5 56.7 31.5H77.9C78.1 31.5 78.4 31.6 78.4 31.8L82.1 40.7H117.3C117.7 40.7 118 41 118 41.4V48.6H56V32.2ZM148 97.2C148 97.8 147.6 98.2 147 98.2H112.1H81C80.4 98.2 80 97.8 80 97.2V97V77.2C80 76.6 80.4 76.2 81 76.2H147C147.6 76.2 148 76.6 148 77.2V97.2Z"
      fill="#4a495e"
    />
  </svg>
);

export default BuildingProject;
