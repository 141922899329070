import React from "react";
import PropTypes from "prop-types";

const Code = ({ color = "#fff" }) => (
  <svg
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill-target"
      d="M16.8674 14.7196C16.5804 14.7196 16.3195 14.6152 16.1109 14.4065C15.7195 13.9891 15.7195 13.337 16.1369 12.9196L21.4298 7.86145L16.1369 2.80334C15.7195 2.41203 15.7195 1.73377 16.1109 1.31638C16.5022 0.898992 17.1804 0.898992 17.5978 1.2903L23.6733 7.10493C23.882 7.31362 23.9864 7.57449 23.9864 7.86145C23.9864 8.14841 23.882 8.40928 23.6733 8.61797L17.5978 14.4326C17.3891 14.6152 17.1282 14.7196 16.8674 14.7196Z"
      fill={color}
    />
    <path
      className="fill-target"
      d="M7.11895 0.752306C7.40591 0.752306 7.66678 0.856653 7.87547 1.06535C8.26678 1.48274 8.26678 2.13492 7.84939 2.55231L2.55649 7.61045L7.84939 12.6686C8.26678 13.0599 8.26678 13.7381 7.87548 14.1555C7.48417 14.5729 6.80591 14.5729 6.38852 14.1816L0.313013 8.36697C0.104318 8.15827 -3.01387e-05 7.8974 -3.01638e-05 7.61045C-3.01888e-05 7.32349 0.104318 7.06262 0.313013 6.85392L6.38852 1.03926C6.59721 0.856653 6.85808 0.752306 7.11895 0.752306Z"
      fill={color}
    />
  </svg>
);

Code.propTypes = {
  color: PropTypes.string
};

export default Code;
