import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { StripeProvider, Elements } from "react-stripe-elements";

import StepForm from "Components/setup/StepForm";
import PaymentForm from "Components/PaymentForm";
import StepHeader from "Components/setup/StepHeader";
import StepContent from "Components/setup/StepContent";
import Label from "Components/fields/Label";
import Heading2 from "Components/styleguide/Heading2";
import PlanInfo from "Components/setup/PlanInfo";

const Description = styled.div`
  &:first-letter {
    text-transform: capitalize;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  align-items: flex-stretch;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .setting-line {
    margin-bottom: 30px;
    border: 1px solid rgba(152, 160, 171, 0.4);
    background: #f5f7fa;
    padding: 0 10px;
    min-height: 40px;
  }
  .new-card {
    margin-bottom: 30px;
    button + button {
      margin-left: 10px;
    }
  }
`;

const ReviewWrapper = styled.aside`
  width: 402px;
  min-width: 402px;
  background: #f1f1f4;
  padding: 100px 40px 40px;
  box-sizing: border-box;
  color: #38485e;
  h2 {
    line-height: 33px;
    margin-bottom: 16px;
  }
  hr {
    margin-bottom: 24px;
  }
  .estimated-total {
    label {
      font-size: 15px;
      font-weight: 600;
      line-height: 33px;
      color: #38485e;
      margin-bottom: 0;
    }
    .price {
      color: #0e0d34;
      font-size: 32px;
      line-height: 33px;
      margin-bottom: 16px;
      font-weight: 700;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  .step-counter-wrapper {
    margin-bottom: 40px;
  }
`;

const ReviewItem = styled.div`
  margin-bottom: 16px;
  label {
    font-size: 15px;
    font-weight: 600;
    line-height: 33px;
    color: #38485e;
    margin-bottom: 0;
  }
  &.review-plan .plan {
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const Value = styled.div`
  color: #38485e;
  font-size: 15px;
  line-height: 25px;
`;

class PaymentStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.goForward = this.goForward.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goForward() {
    this.props.goForward({ billing: this.state });
  }

  goBack() {
    this.props.goBack(this.state);
  }

  showCardForm(price, credit) {
    if (price && price.amount && credit && credit.amount) {
      if (price.amount !== 0 && price.amount <= credit.amount) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      intl,
      activeStep,
      totalSteps,
      step,
      region,
      plan,
      info,
      account,
      accountId,
      theme,
      template,
      estimate
    } = this.props;
    const price = plan && plan.price;
    const credit = estimate && estimate.credit;
    const stripePublicKey = this.props.stripePublicKey
      ? this.props.stripePublicKey
      : "pk_test_6pRNASCoBOKtIshFeQd4XMUh";

    return (
      <ColumnWrapper>
        <ContentWrapper>
          <StepHeader
            title={intl.formatMessage({ id: "billing" })}
            currentStep={activeStep}
            totalStepNumber={totalSteps}
          >
            <Description>
              {intl.formatMessage({ id: "payment_description" })}
            </Description>
          </StepHeader>
          <StepContent>
            <StepForm
              parentTag="div"
              step={step}
              activeStep={activeStep}
              goForward={this.goForward}
              goBack={this.goBack}
              hideButtons={true}
              totalStepNumber={totalSteps}
            >
              <StripeProvider apiKey={stripePublicKey}>
                <Elements>
                  <PaymentForm
                    goBack={this.goBack}
                    goForward={this.goForward}
                    goForwardText={intl.formatMessage({
                      id: "create_new_project"
                    })}
                    showCardForm={this.showCardForm(price, credit)}
                    goBackText={intl.formatMessage({ id: "back" })}
                    estimate={estimate}
                    plan={plan}
                  />
                </Elements>
              </StripeProvider>
            </StepForm>
          </StepContent>
        </ContentWrapper>
        <ReviewWrapper className="review-wrapper">
          <div className="plan-info">
            <Heading2>Summary</Heading2>
            <ReviewItem className="review-account">
              <Label>User</Label>
              <Value id="user-name" className="user" data-uid={accountId}>
                {account}
              </Value>
            </ReviewItem>
            {region && (
              <ReviewItem className="review-region">
                <Label>Region</Label>
                <Value
                  id="region-name"
                  className="region"
                  data-region={region.id}
                >
                  {region.label}
                </Value>
              </ReviewItem>
            )}
            {info && (
              <ReviewItem className="review-info">
                <Label>Project</Label>
                <Value id="project-name" className="project">
                  {info.projectTitle}
                </Value>
              </ReviewItem>
            )}
            {template && (
              <ReviewItem className="review-template">
                <Label>Code base</Label>
                <Value
                  id="template-name"
                  className="code-base"
                  data-template={template.info.id}
                >
                  {template.info.id === "platformsh/empty"
                    ? "Blank project"
                    : template.info.name}
                </Value>
              </ReviewItem>
            )}
            {plan && (
              <React.Fragment>
                <ReviewItem className="review-plan">
                  <Label>Plan size</Label>
                  <Value id="plan-name" className="plan" data-sku={plan.sku}>
                    {plan.size}
                  </Value>
                  <Value className="extras">
                    1 user, 3 environments, 5 GB storage
                  </Value>
                </ReviewItem>
                <ReviewItem className="review-includes">
                  <Label>Includes</Label>
                  <Value>
                    <PlanInfo
                      plan={plan.label}
                      subtitles={false}
                      title=""
                      theme={theme}
                    />
                  </Value>
                </ReviewItem>
              </React.Fragment>
            )}
          </div>
          <hr />
          <div className="estimated-total">
            <span className="price">{price && price.formatted}</span>
            <Label>{intl.formatMessage({ id: "estimated_total" })}</Label>
          </div>
        </ReviewWrapper>
      </ColumnWrapper>
    );
  }
}

PaymentStep.propTypes = {
  initialization: PropTypes.object,
  intl: PropTypes.object,
  activeStep: PropTypes.number,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  region: PropTypes.object,
  plan: PropTypes.object,
  info: PropTypes.object,
  template: PropTypes.object,
  account: PropTypes.string,
  accountId: PropTypes.string,
  theme: PropTypes.object,
  stripePublicKey: PropTypes.string,
  estimate: PropTypes.object
};

const mapStateToProps = state => ({
  stripePublicKey:
    state.app && state.app.getIn(["me", "data", "stripe", "public_key"])
});

export default connect(mapStateToProps)(injectIntl(PaymentStep));
